/*
@File: Ervy Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Navbar Area Two CSS
** - Saas Home Area CSS
** - Marketing Home Area CSS
** - Startup Home Area CSS
** - SEO Agency Home Area CSS
** - Freelancer Home Area CSS
** - Features Section CSS
** - Services Section CSS
** - Video Section CSS
** - Counter Section CSS
** - Benefit Section CSS
** - Support Section CSS
** - Software Section CSS
** - Pricing Section CSS
** - Client Section CSS
** - Subscribe Section CSS
** - Partner Section CSS
** - Talk Section CSS
** - Top Services Section CSS
** - What We Do Section CSS
** - History Section CSS
** - Performance Section CSS
** - Productive Section CSS
** - Needs Section CSS
** - Project Section CSS
** - Saying Section CSS
** - Faq Section CSS
** - Provide Section CSS
** - Solutions Section CSS
** - Contact Section CSS
** - Footer Section CSS
** - Back To Top Section CSS
** - Others Section CSS
*/
/*================================================
Default CSS
=================================================*/
body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

a {
  text-decoration: none;
  transition: 0.5s;
  outline: 0 !important;
  color: #343b4e;
}
a:hover {
  text-decoration: none;
  color: #0b4d6b;
}

p {
  font-size: 15px;
  line-height: 1.8;
  color: #737b9a;
  margin-bottom: 15px;
}
p:last-child {
  margin-bottom: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #343b4e;
  font-family: "Rubik", sans-serif;
}

.default-btn {
  display: inline-block;
  color: #0b4d6b;
  background-color: #ffffff;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid #0b4d6b;
  position: relative;
}
.default-btn i {
  position: relative;
  top: 0px;
  padding-left: 6px;
  font-size: 14px;
}
.default-btn:hover {
  background-color: #0b4d6b;
  color: #ffffff;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
}
.section-title span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
  text-transform: capitalize;
}
.section-title h2 {
  font-size: 40px;
  margin: 15px auto 10px;
  max-width: 490px;
}

i::before {
  margin-left: 0;
}

/*=============================
Preloader CSS
===============================*/
.preloader-area {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0b4d6b;
  z-index: 999999;
  left: 0;
  top: 0;
}

.spinner {
  margin: -85px auto 0 -65px;
  width: 65px;
  height: 65px;
  position: relative;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  left: 50%;
  top: 50%;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #ffffff;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/*================================================
Navbar Area CSS
=================================================*/
.exto-responsive-nav {
  display: none;
}

.exto-nav {
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
}
.exto-nav .navbar {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.exto-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.exto-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.exto-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.exto-nav .navbar .navbar-nav .nav-item a {
  font-size: 16px;
  font-weight: 600;
  color: #343b4e;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.exto-nav .navbar .navbar-nav .nav-item a:hover, .exto-nav .navbar .navbar-nav .nav-item a:focus, .exto-nav .navbar .navbar-nav .nav-item a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
  position: relative;
  top: 2px;
  margin-left: 1px;
}
.exto-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.exto-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.exto-nav .navbar .navbar-nav .nav-item:hover a, .exto-nav .navbar .navbar-nav .nav-item:focus a, .exto-nav .navbar .navbar-nav .nav-item.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 230px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 0;
  margin: 0;
  color: #5b5b98;
  font-size: 15px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #5b5b98;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #5b5b98;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #5b5b98;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 195px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #5b5b98;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 195px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #5b5b98;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 195px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #5b5b98;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #0b4d6b;
}
.exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.exto-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.exto-nav .navbar .others-options {
  margin-left: 5px;
}
.exto-nav .navbar .others-options .language-list {
  display: inline-block;
}
.exto-nav .navbar .others-options .language-list .nice-select {
  background-color: transparent;
  border: none;
}
.exto-nav .navbar .others-options .language-list .nice-select span {
  font-size: 16px;
  color: #737b9a;
  transition: all 0.5s;
  font-weight: 600;
}
.exto-nav .navbar .others-options .language-list .nice-select span:hover {
  color: #0b4d6b;
}
.exto-nav .navbar .others-options .language-list .nice-select::after {
  height: 9px;
  width: 9px;
  border-bottom: 3px solid #737b9a !important;
  border-right: 3px solid #737b9a !important;
  transition: all 0.5s;
}
.exto-nav .navbar .others-options .language-list .nice-select.open .list {
  border-radius: 0;
  box-shadow: none;
}
.exto-nav .navbar .others-options .language-list .nice-select .option.focus {
  color: #ffffff !important;
  background-color: #0b4d6b !important;
}
.exto-nav .navbar .others-options .language-list .nice-select .option:hover {
  color: #ffffff;
  background-color: #0b4d6b !important;
}
.exto-nav .navbar .others-options .language-list .nice-select:hover::after {
  border-bottom: 3px solid #0b4d6b !important;
  border-right: 3px solid #0b4d6b !important;
}
.exto-nav .navbar .others-options .language-list .nice-select:hover span {
  color: #0b4d6b;
}
.exto-nav .navbar .others-options .saas-nav-btn {
  background-color: #0b4d6b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  margin-left: 10px;
  transition: all 0.5s;
  font-size: 16px;
  font-weight: 600;
}
.exto-nav .navbar .others-options .saas-nav-btn i {
  font-size: 14px;
}
.exto-nav .navbar .others-options .saas-nav-btn:hover {
  background-color: #343b4e;
  color: #ffffff !important;
}
.exto-nav .navbar .others-options.saas-option {
  margin: 0;
}
.exto-nav .navbar .others-options.saas-option .saas-nav-btn {
  background-color: transparent;
  border: 1px solid #ffffff;
}
.exto-nav .navbar .others-options.saas-option .saas-nav-btn:hover {
  background-color: #0b4d6b;
  color: #ffffff;
  border: 1px solid #0b4d6b;
}
.exto-nav .navbar .others-options.startup-option .language-list .nice-select span {
  color: #5b5b98;
  transition: all 0.5s;
}
.exto-nav .navbar .others-options.startup-option .language-list .nice-select::after {
  border-bottom: 3px solid #5b5b98 !important;
  border-right: 3px solid #5b5b98 !important;
  transition: all 0.5s;
}
.exto-nav .navbar .others-options.startup-option .language-list .nice-select .option.focus {
  color: #ffffff !important;
  background-color: #0b4d6b !important;
}
.exto-nav .navbar .others-options.startup-option .language-list .nice-select .option:hover {
  color: #ffffff;
  background-color: #0b4d6b !important;
}
.exto-nav .navbar .others-options.startup-option .language-list .nice-select:hover::after {
  border-bottom: 3px solid #0b4d6b !important;
  border-right: 3px solid #0b4d6b !important;
}
.exto-nav .navbar .others-options.startup-option .language-list .nice-select:hover span {
  color: #0b4d6b;
}
.exto-nav .navbar .others-options.startup-option .sidebar-menu {
  cursor: pointer;
  background-color: #002c8c;
  padding: 5px;
  display: inline-block;
  position: relative;
  top: 7px;
  margin-left: 10px;
}
.exto-nav .navbar .others-options.startup-option .sidebar-menu span {
  border-bottom: 1px solid #ffffff;
  display: block;
  padding-bottom: 5px;
  width: 20px;
}
.exto-nav .navbar .others-options.startup-option .sidebar-menu span:first-child {
  padding-bottom: 0;
}

.navbar-area {
  background-color: transparent;
  transition: 0.5s;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: 0.5s;
}

.search-overlay {
  display: none;
}
.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}
.search-overlay.search-popup .search-form {
  position: relative;
}
.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #343b4e;
  outline: 0;
  transition: 0.5s;
  padding-top: 4px;
  padding-left: 10px;
}
.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #0b4d6b;
}
.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: #737b9a;
  transition: 0.5s;
  padding: 0;
}
.search-overlay.search-popup .search-form .search-button:hover, .search-overlay.search-popup .search-form .search-button:focus {
  color: #0b4d6b;
}

@media only screen and (max-width: 991px) {
  .navbar-area {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar-area.is-sticky {
    border-bottom: none;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .exto-responsive-nav {
    display: block;
  }
  .exto-responsive-nav .exto-responsive-menu {
    position: relative;
  }
  .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul {
    font-size: 14px;
  }
  .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul li a {
    font-size: 15px;
  }
  .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #0b4d6b;
  }
  .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .exto-responsive-nav .exto-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 336px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .exto-responsive-nav .exto-responsive-menu.mean-container .others-options {
    display: none;
  }
  .exto-responsive-nav .mean-container a.meanmenu-reveal {
    color: #343b4e;
  }
  .exto-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #343b4e;
  }
  .exto-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }
  .exto-nav {
    display: none;
  }
}
/*================================================
Navbar Area Two CSS
=================================================*/
.navbar-area-two {
  background-color: transparent;
  transition: 0.5s;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}
.navbar-area-two.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: -8px 20px 28px 0 rgba(255, 255, 255, 0.06);
  background: #150346 !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: 0.5s;
}
.navbar-area-two.is-sticky .exto-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}
.navbar-area-two.is-sticky .exto-nav .navbar .others-options {
  margin-left: 30px;
}
.navbar-area-two.is-sticky .exto-nav .navbar .others-options .option-item {
  color: #ffffff;
}
.navbar-area-two.is-sticky .exto-nav .navbar .others-options .option-item .search-btn {
  color: #ffffff;
}
.navbar-area-two.is-sticky .exto-nav .navbar .others-options .option-item .close-btn {
  color: #ffffff;
}
.navbar-area-two .exto-responsive-nav {
  display: none;
}
.navbar-area-two .exto-nav {
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
}
.navbar-area-two .exto-nav .navbar {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.navbar-area-two .exto-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar-area-two .exto-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item a {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
  position: relative;
  top: 2px;
  margin-left: 1px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item:hover a, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item:focus a, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 230px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 0;
  margin: 0;
  color: #000000;
  font-size: 15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #696997;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #000000;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #696997;
  text-transform: capitalize;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #696997;
  text-transform: capitalize;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 195px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #696997;
  text-transform: capitalize;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 195px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #696997;
  text-transform: capitalize;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 195px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #696997;
  text-transform: capitalize;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area-two .exto-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.navbar-area-two .exto-nav .navbar .others-options {
  margin-left: 30px;
}
.navbar-area-two .exto-nav .navbar .others-options .option-item {
  color: #ffffff;
  display: inline-block;
  position: relative;
  line-height: 1;
  margin-left: 10px;
  width: 15px;
}
.navbar-area-two .exto-nav .navbar .others-options .option-item .search-btn {
  cursor: pointer;
  transition: 0.5s;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
}
.navbar-area-two .exto-nav .navbar .others-options .option-item .search-btn:hover {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .others-options .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: 0.5s;
  width: 20px;
  color: #ffffff;
  font-size: 15px;
}
.navbar-area-two .exto-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}
.navbar-area-two .exto-nav .navbar .others-options .option-item .close-btn:hover {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option {
  margin-left: 0 !important;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .sidebar-menu-icon {
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list {
  display: inline-block;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select {
  background-color: transparent;
  border: none;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select span {
  color: #ffffff;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select span:hover {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select::after {
  border-bottom: 3px solid #ffffff !important;
  border-right: 3px solid #ffffff !important;
  transition: all 0.5s;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select .option.focus {
  color: #ffffff !important;
  background-color: #0b4d6b !important;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select .option:hover {
  color: #ffffff;
  background-color: #0b4d6b !important;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select:hover::after {
  border-bottom: 3px solid #0b4d6b !important;
  border-right: 3px solid #0b4d6b !important;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .language-list .nice-select:hover span {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .seo-cart-icon {
  display: inline-block;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .seo-cart-icon a {
  color: #ffffff;
  position: relative;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .seo-cart-icon a:hover {
  color: #0b4d6b;
}
.navbar-area-two .exto-nav .navbar .others-options.seo-option .seo-cart-icon a .point {
  position: absolute;
  top: -12px;
  left: -6px;
  background-color: red;
  width: 15px;
  height: 15px;
  font-size: 10px;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
}
.navbar-area-two.freelancer-nav .exto-nav .navbar .navbar-nav {
  margin-right: 0;
}

@media only screen and (max-width: 991px) {
  .navbar-area-two {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar-area-two.is-sticky {
    border-bottom: none;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area-two .exto-responsive-nav {
    display: block;
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu {
    position: relative;
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul {
    font-size: 14px;
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul li a {
    font-size: 15px;
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #0b4d6b;
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 336px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-area-two .exto-responsive-nav .exto-responsive-menu.mean-container .others-options {
    display: none;
  }
  .navbar-area-two .exto-responsive-nav .mean-container a.meanmenu-reveal {
    color: #ffffff;
  }
  .navbar-area-two .exto-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #ffffff;
  }
  .navbar-area-two .exto-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }
  .navbar-area-two .exto-nav {
    display: none;
  }
}
/*================================================
Saas Home Area CSS
=================================================*/
.saas-home-area {
  position: relative;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
  background-image: url(../img/saas-banner/saas-banner.webp);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.saas-home-area .hero-content {
  margin-top: 70px;
}
.saas-home-area .hero-content h1 {
  font-size: 60px;
  color: #343b4e;
  font-weight: bold;
  margin: 20px 0 30px 0;
}
.saas-home-area .hero-content p {
  color: #7d8087;
  font-weight: 500;
  margin: 0 0 0 0;
  font-size: 16px;
}
.saas-home-area .hero-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
}
.saas-home-area .hero-content .saas-btn {
  margin-top: 35px;
}
.saas-home-area .hero-content .saas-btn .saas-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #0b4d6b;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 5px;
}
.saas-home-area .hero-content .saas-btn .saas-btn-one i {
  position: relative;
  top: 0px;
  padding-left: 6px;
  font-size: 14px;
}
.saas-home-area .hero-content .saas-btn .saas-btn-one:hover {
  background-color: #343b4e;
  color: #ffffff !important;
}
.saas-home-area .hero-content .saas-btn .saas-btn-two {
  display: inline-block;
  color: #ffffff;
  background-color: #0b4d6b;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 5px;
  margin-left: 15px;
}
.saas-home-area .hero-content .saas-btn .saas-btn-two i {
  position: relative;
  top: 0px;
  padding-left: 6px;
  font-size: 14px;
}
.saas-home-area .hero-content .saas-btn .saas-btn-two:hover {
  background-color: #343b4e;
  color: #ffffff;
}

.saas-image {
  position: relative;
}
.saas-image img {
  position: absolute;
  left: 0;
  top: 40px;
  animation: movebounce 5s linear infinite;
  width: 60%;
}
.saas-image img:last-child {
  animation: movebounce 6s linear infinite;
  left: 250px;
  top: 50px;
}

.circle-img {
  position: absolute;
  left: 55%;
  top: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  right: 0;
  margin: 0 auto;
  width: 35%;
}
.circle-img img {
  animation-name: rotateMe;
  animation-duration: 35s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img1 {
  position: absolute;
  left: 10%;
  bottom: 25%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.shape-img2 {
  position: absolute;
  left: 5%;
  top: 10%;
  z-index: -1;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img3 {
  position: absolute;
  right: 10%;
  top: 10%;
  z-index: -1;
  -webkit-animation: animationFramesOne 20s infinite linear;
  animation: animationFramesOne 20s infinite linear;
}

.shape-img4 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  z-index: -1;
  top: 20%;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
}

.shape-img5 {
  position: absolute;
  left: 20%;
  top: 20%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(88px);
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}
/*================================================
Features Section CSS
=================================================*/
.features-section {
  position: relative;
}

.features-area-content h2 {
  font-size: 40px;
  margin: 20px 0 16px 0;
}
.features-area-content p {
  font-weight: 400;
  color: #a0a0a0;
  margin: 0 0 0 0;
}
.features-area-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
}
.features-area-content .features-text {
  margin-top: 30px;
}
.features-area-content .features-text h3 {
  font-size: 18px;
  margin: 18px 0 8px 0;
}
.features-area-content .features-text p {
  color: #a0a0a0;
  margin: 0 0 0 0;
}
.features-area-content .features-text.bg-f4ffb3 .icon i {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50px;
  background-color: #f4ffb3;
  text-align: center;
}
.features-area-content .features-text.bg-f4ffb3 .icon i::before {
  font-size: 35px;
}
.features-area-content .features-text.bg-ffe7dc .icon i {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50px;
  background-color: #ccedfc;
  text-align: center;
}
.features-area-content .features-text.bg-ffe7dc .icon i::before {
  font-size: 35px;
}
.features-area-content .features-btn {
  margin-top: 30px;
}

.features-image {
  position: relative;
}
.features-image .features-shape {
  position: absolute;
  bottom: -40px;
  z-index: -1;
  width: 638px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}
.features-image .features-arrow {
  position: absolute;
  top: -18px;
  z-index: -1;
  right: 50px;
  width: 160px;
}

/*================================================
Services Section CSS
=================================================*/
.services-section {
  position: relative;
}

.services-area-content h3 {
  font-size: 36px;
  margin: 21px 0 20px 0;
}
.services-area-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
}
.services-area-content p {
  color: #a0a0a0;
  font-weight: 400;
}
.services-area-content .services-btn {
  margin-top: 30px;
}

.col-lg-6 .row .col-lg-6:nth-child(1) .services-image {
  border-right: 1px solid #f9f5f5;
  border-bottom: 1px solid #f9f5f5;
}

.col-lg-6 .row .col-lg-6:nth-child(3) .services-image {
  border-right: 1px solid #f9f5f5;
}

.col-lg-6 .row .col-lg-6:nth-child(4) .services-image {
  border-top: 1px solid #f9f5f5;
}

.single-services {
  position: relative;
}
.single-services .services-image {
  text-align: center;
  transition: 0.5s;
  padding: 30px 20px;
  position: relative;
}
.single-services .services-image h3 {
  font-size: 16px;
  color: #3e435e;
  margin: 16px 0 0 0;
}
.single-services .services-image:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

/*================================================
Video Section CSS
=================================================*/
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
/*================================================
Counter Section CSS
=================================================*/
.fun-facts-area {
  background-image: linear-gradient(to top, #ffffff, #f5f4df);
}

.single-fun-fact {
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  transition: 0.5s;
  margin-bottom: 30px;
}
.single-fun-fact h3 {
  position: relative;
  color: #343b4e;
  margin-bottom: 8px;
  font-size: 35px;
  font-weight: 700;
}
.single-fun-fact h3 .sign-icon {
  display: inline-block;
  font-size: 30px;
  margin-left: 2px;
}
.single-fun-fact p {
  line-height: initial;
  color: #343b4e;
  margin: 0 0 0 0;
  font-weight: 600;
  padding-bottom: 23px;
  position: relative;
}
.single-fun-fact p::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: #343c4e;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.single-fun-fact:hover {
  transform: translateY(-10px);
}

/*================================================
Benefit Section CSS
=================================================*/
.benefit-section {
  position: relative;
}

.benefit-area-content {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 40px 30px;
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
  transition: 0.5s;
}
.benefit-area-content i {
  position: absolute;
  left: 30px;
  color: #0b4d6b;
  top: 32px;
  transition: 0.5s;
}
.benefit-area-content i::before {
  font-size: 50px;
}
.benefit-area-content h3 {
  font-size: 20px;
  margin: 0 0 14px 0;
  transition: 0.5s;
}
.benefit-area-content p {
  color: #a0a0a0;
  font-weight: 500;
  transition: 0.5s;
}
.benefit-area-content:hover {
  background-color: #0b4d6b;
  transform: translateY(-10px);
}
.benefit-area-content:hover i {
  color: #ffffff;
}
.benefit-area-content:hover h3 {
  color: #ffffff;
}
.benefit-area-content:hover p {
  color: #ffffff;
}

/*================================================
Support Section CSS
=================================================*/
.support-section {
  position: relative;
}

.support-image {
  position: relative;
  text-align: center;
}
.support-image .support-shape {
  position: absolute;
  top: 40px;
  z-index: -1;
  width: 638px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  text-align: center;
  right: -33px;
}
.support-image .support-arrow {
  position: absolute;
  top: -4px;
  z-index: -1;
  right: 90px;
  width: 170px;
}

.support-area-content h3 {
  font-size: 36px;
  margin: 21px 0 20px 0;
}
.support-area-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
  text-transform: capitalize;
}
.support-area-content p {
  color: #a0a0a0;
  font-weight: 400;
}
.support-area-content .support-btn {
  margin-top: 25px;
}

/*================================================
Software Section CSS
=================================================*/
.software-section {
  position: relative;
}

.software-title {
  text-align: center;
  max-width: 620px;
  margin: 0 auto 50px;
}
.software-title h2 {
  font-size: 40px;
  margin: -9px 0 0 0;
}

.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}

.software-list-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 50px;
}
.software-list-tab .tabs li {
  -ms-flex: 0 0 16.6666666667%;
  -webkit-box-flex: 0;
  flex: 0 0 20.6666666667%;
  max-width: 20.6666666667%;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
}
.software-list-tab .tabs li a {
  display: block;
  border-radius: 2px;
  color: #292a2c;
  box-shadow: 0px 10px 40px 0px rgba(107, 125, 179, 0.11);
  padding-right: 18px;
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 18px;
  font-size: 16px;
  font-weight: 700;
}
.software-list-tab .tabs li a i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-weight: normal;
  font-size: 20px;
  line-height: normal;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #ccf6fc;
  border-radius: 50px;
  color: #424657;
  margin-right: 8px;
}
.software-list-tab .tabs li.current a, .software-list-tab .tabs li:hover a {
  background-color: #ffffff;
  color: #0b4d6b;
  box-shadow: 0px 10px 40px 0px rgba(222, 222, 222, 0.52);
}
.software-list-tab .tabs li.current a i, .software-list-tab .tabs li:hover a i {
  color: #ffffff;
  background-color: #0b4d6b;
}
.software-list-tab .tab_content .tabs_item .tab-image {
  position: relative;
}
.software-list-tab .tab_content .tabs_item .tab-image .tab-shape {
  position: absolute;
  bottom: 20px;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  text-align: center;
  left: -80px;
  width: 1230px;
}
.software-list-tab .tab_content .tabs_item .tab-image .tab-arrow {
  position: absolute;
  top: -25px;
  z-index: -1;
  left: -33px;
  width: 70px;
}
.software-list-tab .tab_content .tabs_item .tab-image .tab-arrow-two {
  position: absolute;
  bottom: -25px;
  z-index: -1;
  right: 50px;
  width: 70px;
}

/*================================================
Pricing Section CSS
=================================================*/
.pricing-section {
  position: relative;
  padding-bottom: 130px;
}
.pricing-section .pricing-btn-wrap .price-btn {
  margin-top: 25px;
  display: inline-block;
}
.pricing-section .pricing-btn-wrap .price-btn .price-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #0b4d6b;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid #0b4d6b;
  position: relative;
}
.pricing-section .pricing-btn-wrap .price-btn .price-btn-one i {
  font-size: 14px;
}
.pricing-section .pricing-btn-wrap .price-btn .price-btn-one.price-two {
  margin-left: 15px;
  background-color: transparent;
  color: #0b4d6b;
  border: 1px solid #0b4d6b;
}
.pricing-section .pricing-btn-wrap .price-btn .price-btn-one.price-two:hover {
  border: 1px solid #0b4d6b;
  background-color: #0b4d6b;
  color: #ffffff;
}
.pricing-section .pricing-btn-wrap .price-btn .price-btn-one:hover {
  background-color: transparent;
  color: #0b4d6b;
  border: 1px solid #0b4d6b;
}

.pricing-area-content h3 {
  font-size: 36px;
  margin: 21px 0 20px 0;
}
.pricing-area-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
  text-transform: capitalize;
}
.pricing-area-content p {
  color: #a0a0a0;
  font-weight: 400;
}
.pricing-area-content.seo-price-title span {
  background-color: transparent;
  display: block;
  margin-top: -5px;
}

.single-pricing-box {
  background-color: #ffffff;
  transition: 0.5s;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 10px 40px 13px rgba(222, 222, 222, 0.52);
  position: relative;
}
.single-pricing-box.seo-price.price-z {
  z-index: 111;
}
.single-pricing-box.seo-price .pricing-header {
  background-color: #150346;
  position: relative;
  z-index: 1;
}
.single-pricing-box.seo-price .pricing-header::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  width: 100%;
  height: 35px;
  background-color: #ffffff;
  z-index: -1;
  border-radius: 50%;
}
.single-pricing-box.seo-price .pricing-header::after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 100%;
  height: 35px;
  background-color: #ffffff;
  z-index: -1;
  border-radius: 50%;
}
.single-pricing-box.seo-price .pricing-header h3 {
  color: #bbb1de;
}
.single-pricing-box.seo-price .price {
  color: #0b4d6b;
}
.single-pricing-box.seo-price .price span {
  color: #3e435e;
}
.single-pricing-box .pricing-header {
  background-color: #f5f4df;
  padding: 30px;
}
.single-pricing-box .pricing-header h3 {
  font-size: 20px;
  color: #5b616f;
  margin: 0 0 0 0;
}
.single-pricing-box .price {
  font-size: 30px;
  color: #5b616f;
  font-weight: bold;
  padding: 20px;
  position: relative;
}
.single-pricing-box .price::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 180px;
  background-color: #e5e3eb;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: auto;
}
.single-pricing-box .price span {
  font-size: 14px;
  font-weight: 400;
}
.single-pricing-box .pricing-features {
  padding-left: 30px;
  margin-bottom: 0;
  text-align: left;
}
.single-pricing-box .pricing-features li {
  list-style-type: none;
  font-size: 16px;
  color: #827f8d;
  font-weight: 400;
  margin-bottom: 5px;
}
.single-pricing-box .pricing-features li:last-child {
  margin-bottom: 0;
}
.single-pricing-box .pricing-features i {
  color: #ffd0ba;
  margin-right: 5px;
  position: relative;
  top: 1px;
  font-size: 16px;
  font-weight: bold;
}
.single-pricing-box .buy-btn {
  margin-top: 20px;
}
.single-pricing-box .buy-btn .buy-btn-one {
  display: inline-block;
  padding: 14px 20px;
  background-color: #ffffff;
  color: #0b4d6b;
  border: 2px solid #ccf6fc;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
}
.single-pricing-box .buy-btn .buy-btn-one:hover {
  background-color: #ccf6fc;
  color: #0b4d6b;
}
.single-pricing-box.table-price {
  margin-left: 0;
  position: relative;
  left: -40px;
  top: 30px;
}

/*================================================
Client Section CSS
=================================================*/
.client-section {
  position: relative;
  z-index: 1;
  background: radial-gradient(circle, #f5f4df, #f7f6e1, #faf9e3, #fcfbe6, #fffee8);
}
.client-section .client-logo img {
  width: unset;
}
.client-section .owl-prev {
  position: absolute;
  bottom: 40px;
  right: 20px;
  transition: all 0.5s;
}
.client-section .owl-prev i {
  width: 50px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #0b4d6b;
  color: #0b4d6b;
  transition: all 0.5s;
}
.client-section .owl-prev i:hover {
  color: #ffffff;
  background-color: #0b4d6b;
}
.client-section .owl-next {
  position: absolute;
  top: 0;
}

.client-image {
  position: relative;
}
.client-image img {
  border-radius: 0 0 50% 50%;
}
.client-image .client-shape {
  position: absolute;
  bottom: 14px;
  width: 410px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  text-align: center;
  right: 20px;
  z-index: -1;
}

.client-content-area {
  background-color: #ffffff;
  padding: 35px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}
.client-content-area h3 {
  font-size: 20px;
  margin: 0 0 0 0;
}
.client-content-area span {
  font-size: 13px;
  color: #909090;
  font-weight: 400;
}
.client-content-area p {
  font-weight: 400;
  color: #909090;
  margin: 16px 0 0 0;
}
.client-content-area .client-logo {
  margin-top: 20px;
}
.client-content-area .icon {
  position: absolute;
  bottom: 36px;
  right: 50px;
}
.client-content-area .icon i {
  color: #0b4d6b;
  background-color: transparent;
  border: 2px solid #0b4d6b;
  height: 30px;
  width: 50px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  border-radius: 2px;
  font-size: 16px;
}

/*================================================
Subscribe Section CSS
=================================================*/
.subscribe-section {
  position: relative;
  overflow: hidden;
}

.subscribe-area-content {
  margin-left: auto;
  max-width: 542px;
}
.subscribe-area-content h3 {
  font-size: 36px;
  margin: 21px 0 20px 0;
}
.subscribe-area-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
  text-transform: capitalize;
}
.subscribe-area-content p {
  color: #a0a0a0;
  font-weight: 400;
  margin: 0 0 0 0;
}
.subscribe-area-content .newsletter-form {
  max-width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.subscribe-area-content .newsletter-form .input-newsletter {
  display: block;
  width: 68%;
  background-color: #f1f1f1;
  border: none;
  height: 50px;
  padding-left: 25px;
  border-radius: 4px;
  padding-top: 0;
  outline: 0;
  color: #343b4e;
}
.subscribe-area-content .newsletter-form .input-newsletter::placeholder {
  color: #737b9a;
}
.subscribe-area-content .newsletter-form button {
  position: absolute;
  right: -12px;
  top: 0;
  background-color: #0b4d6b;
  color: #ffffff;
  border: none;
  padding: 14px 30px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  font-size: 15px;
  font-weight: 400;
}
.subscribe-area-content .newsletter-form button:hover {
  background-color: #343b4e;
  color: #ffffff;
}
.subscribe-area-content .newsletter-form button i {
  margin-left: 5px;
}
.subscribe-area-content .social-list {
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 35px;
}
.subscribe-area-content .social-list li {
  display: inline-block;
}
.subscribe-area-content .social-list li.list-heading {
  color: #a0a0a0;
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 400;
}
.subscribe-area-content .social-list li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #f45a43;
  border: 1px solid #f45a43;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
  text-align: center;
}
.subscribe-area-content .social-list li a:hover {
  background-color: transparent;
  color: #f45a43;
  transition: 0.6s;
  -webkit-transition: 0.5s;
}

.subscribe-image {
  text-align: right;
  position: relative;
  z-index: 1;
}
.subscribe-image .main-image img:nth-child(2) {
  position: absolute;
  left: 40px;
  top: 15px;
}
.subscribe-image .main-image img:nth-child(3) {
  position: absolute;
  left: 40px;
  top: 170px;
}
.subscribe-image .main-image img:nth-child(4) {
  position: absolute;
  left: 40px;
  top: 343px;
  display: none;
}
.subscribe-image .subscribe-mobile-image {
  display: none;
}

@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
/*================================================
Partner Section CSS
=================================================*/
.partner-section {
  line-height: 1;
  position: relative;
}

.partner-slider .partner-item {
  text-align: center;
}
.partner-slider .partner-item img {
  display: inline-block;
  margin: auto;
  width: 160px;
}

/*================================================
Talk Section CSS
=================================================*/
.talk-section {
  position: relative;
}

.talk-form {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
}
.talk-form form .form-control {
  height: 55px;
  padding: 10px;
  font-size: 15px;
  color: #0e314c;
  border: 1px solid #f8f8f8;
  border-radius: 0;
  background: #f8f8f8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.talk-form form .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.talk-form form textarea {
  height: 150px !important;
}
.talk-form form .btn {
  font-weight: 400;
  font-size: 16px;
  border: none;
  padding: 15px 40px;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  margin-top: 20px;
}
.talk-form form .btn-primary {
  color: #ffffff;
  background-color: #0b4d6b;
  -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
  box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
  transition: 0.5s;
  border: 1px solid #0b4d6b;
}
.talk-form form .btn-primary:hover {
  background-color: #ffffff;
  color: #0b4d6b;
}

.talk-content-area {
  margin-left: 60px;
}
.talk-content-area h3 {
  font-size: 40px;
  margin: 20px 0 30px 0;
}
.talk-content-area span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
  text-transform: capitalize;
}
.talk-content-area p {
  color: #a0a0a0;
  font-weight: 400;
  margin: 0 0 0 0;
}
.talk-content-area .talk-info {
  margin-top: 25px;
}
.talk-content-area .talk-info p {
  color: #a0a0a0;
  font-weight: 400;
  margin: 0 0 0 0;
}
.talk-content-area .talk-info h3 {
  font-size: 25px;
  margin: 10px 0 0 0;
}

.talk-image .talk-shape {
  position: absolute;
  bottom: 30px;
  z-index: -1;
  width: 638px;
  animation: movebounce 5s linear infinite;
  text-align: center;
  left: 40px;
}
.talk-image .talk-arrow {
  position: absolute;
  bottom: -16px;
  z-index: -1;
  left: -10px;
  width: 120px;
}

/*================================================
Map Section CSS
=================================================*/
.map-area {
  position: relative;
  margin-bottom: -6px;
}
.map-area iframe {
  width: 100%;
  height: 480px;
  border: none;
}

/*================================================
Freelancer Info Section CSS
=================================================*/
.freelancer-info-section {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #ff6203;
}

.freelancer-info-content h3 {
  font-size: 30px;
  color: #ffffff;
  margin: 0 0 14px 0;
  position: relative;
}
.freelancer-info-content h3::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 45%;
  background-color: #ffffff;
  bottom: 0;
  right: 48px;
  border-radius: 5px;
}
.freelancer-info-content .info-social {
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
}
.freelancer-info-content .info-social li {
  display: inline-block;
}
.freelancer-info-content .info-social li.list-heading {
  color: #a0a0a0;
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 400;
}
.freelancer-info-content .info-social li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
  text-align: center;
  transition: 0.6s;
  -webkit-transition: 0.5s;
}
.freelancer-info-content .info-social li a:hover {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #0b4d6b;
}

.info-image {
  text-align: center;
}

.freelancer-info-text h3 {
  font-size: 30px;
  color: #ffffff;
  margin: 0 0 10px 0;
  position: relative;
}
.freelancer-info-text h3::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 30%;
  background-color: #ffffff;
  bottom: 0;
  right: 130px;
  border-radius: 5px;
}
.freelancer-info-text a {
  font-size: 20px;
  color: #ffffff;
}

/*================================================
Top Services Section CSS
=================================================*/
.top-services-section {
  position: relative;
  z-index: 1;
}
.top-services-section .col-lg-3:last-child .top-services-content {
  border-right: none;
}

.top-services-area {
  position: relative;
  margin-top: -100px;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 13px 27px 0 rgba(197, 197, 197, 0.44);
}
.top-services-area .top-services-content {
  border-right: 1px solid #e6e2e8;
}
.top-services-area .top-services-content h3 {
  font-size: 20px;
  color: #3e435e;
  margin: 20px 0 12px 0;
}
.top-services-area .top-services-content p {
  color: #827f8d;
  font-weight: 400;
  margin: 0 0 0 0;
}

/*================================================
What We Do Section CSS
=================================================*/
.what-we-do-section {
  position: relative;
}

.we-do-content-area span {
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  text-transform: capitalize;
}
.we-do-content-area h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 18px 0;
}
.we-do-content-area p {
  font-weight: 400;
}
.we-do-content-area .we-btn {
  margin-top: 25px;
}
.we-do-content-area .we-btn .we-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #ff8b00;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ff8b00;
}
.we-do-content-area .we-btn .we-btn-one:hover {
  background-color: #ffffff;
  color: #ff8b00;
}

/*================================================
Achieved Section CSS
=================================================*/
.achieved-section {
  position: relative;
  background-color: #f9f9f9;
  z-index: 1;
}
.achieved-section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/shape9.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  opacity: 0.8;
}
.achieved-section .achieved-title {
  text-align: center;
  margin-bottom: 50px;
}
.achieved-section .achieved-title span {
  display: block;
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  margin-top: -5px;
  text-transform: capitalize;
}
.achieved-section .achieved-title h3 {
  font-size: 40px;
  margin: 10px 0 12px 0;
}
.achieved-section .achieved-title p {
  max-width: 750px;
  margin: auto;
}
.achieved-section .fun-facts-content {
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  transition: 0.5s;
}
.achieved-section .fun-facts-content h3 {
  position: relative;
  color: #624272;
  margin-bottom: 5px;
  font-size: 40px;
  font-weight: 700;
}
.achieved-section .fun-facts-content h3 .sign-icon {
  display: inline-block;
  font-size: 30px;
  margin-left: 2px;
}
.achieved-section .fun-facts-content p {
  line-height: initial;
  color: #6b6f76;
  margin: 0 0 0 0;
}
.achieved-section .fun-facts-content:hover {
  transform: translateY(-10px);
}

/*================================================
History Section CSS
=================================================*/
.history-section {
  position: relative;
}

.history-content-area {
  max-width: 565px;
  margin-right: auto;
}
.history-content-area span {
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  display: block;
  margin-top: -5px;
  text-transform: capitalize;
}
.history-content-area h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 18px 0;
}
.history-content-area p {
  font-weight: 400;
  margin: 0 0 0 0;
}
.history-content-area .history-btn {
  margin-top: 25px;
}
.history-content-area .history-btn .history-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #ff8b00;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ff8b00;
}
.history-content-area .history-btn .history-btn-one:hover {
  background-color: #ffffff;
  color: #ff8b00;
}

/*================================================
Performance Section CSS
=================================================*/
.performance-section {
  position: relative;
  background-color: #f8f7f8;
}

.performance-title {
  text-align: center;
  margin-bottom: 50px;
}
.performance-title span {
  display: block;
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  margin-top: -5px;
}
.performance-title h3 {
  font-size: 40px;
  color: #624272;
  max-width: 720px;
  margin: auto;
}

.single-performance {
  text-align: center;
  position: relative;
}
.single-performance h3 {
  font-size: 18px;
  color: #3e435e;
  max-width: 240px;
  margin: 15px auto 0;
  line-height: 30px;
}
.single-performance .circlechart {
  width: 130px;
  height: 130px;
  margin-left: 55px;
}
.single-performance .circlechart .circle-chart {
  width: 130px;
  height: 130px;
}
.single-performance .circlechart .circle-chart .circle-chart__background {
  stroke: #a9b4c5;
  stroke-width: 0.8;
}
.single-performance .circlechart .circle-chart .circle-chart__circle {
  stroke: #ff8a00 !important;
  stroke-width: 1.8;
}
.single-performance .circlechart .circle-chart .circle-chart__info {
  font-weight: 600;
}
.single-performance .circlechart .circle-chart .circle-chart__info .circle-chart__percent {
  position: relative;
}

/*================================================
Productive Section CSS
=================================================*/
.productive-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 70px;
}
.productive-section .col-lg-4.col-md-6.col-sm-6:nth-child(1) .productive-area-content .icon i {
  background-color: #ffe8cc;
}
.productive-section .col-lg-4.col-md-6.col-sm-6:nth-child(4) .productive-area-content .icon i {
  background-color: #ffe8cc;
}
.productive-section .col-lg-4.col-md-6.col-sm-6:nth-child(3) .productive-area-content .icon i {
  background-color: #ffd8d8;
}
.productive-section .col-lg-4.col-md-6.col-sm-6:nth-child(6) .productive-area-content .icon i {
  background-color: #ffd8d8;
}

.productive-title {
  text-align: center;
  margin-bottom: 50px;
}
.productive-title span {
  display: block;
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  margin-top: -5px;
  text-transform: capitalize;
}
.productive-title h3 {
  font-size: 40px;
  margin: 10px 0 12px 0;
}
.productive-title p {
  max-width: 750px;
  margin: auto;
}

.productive-area-content {
  position: relative;
  padding-left: 75px;
  margin-bottom: 30px;
}
.productive-area-content .icon i {
  display: inline-block;
  position: absolute;
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: #eae7ec;
  color: #444444;
  text-align: center;
  left: 0;
  border-radius: 4px;
}
.productive-area-content .icon i::before {
  font-size: 40px;
}
.productive-area-content h3 {
  font-size: 20px;
  color: #444444;
  margin: 0 0 10px 0;
}
.productive-area-content p {
  color: #827f8d;
  font-weight: 400;
  margin: 0 0 0 0;
}

/*================================================
Needs Section CSS
=================================================*/
.needs-section {
  position: relative;
}

.needs-area-content span {
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  display: block;
  margin-top: -5px;
}
.needs-area-content h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 18px 0;
}
.needs-area-content p {
  font-weight: 400;
  margin: 0 0 0 0;
}
.needs-area-content .needs-text {
  margin-top: 25px;
}
.needs-area-content .needs-text p {
  color: #464b54;
  margin: 0 0 0 0;
  position: relative;
  padding-left: 45px;
}
.needs-area-content .needs-text p::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 30px;
  left: 0;
  top: 16px;
  background-color: #ff8b00;
}
.needs-area-content .needs-btn {
  margin-top: 30px;
}
.needs-area-content .needs-btn .needs-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #ff8b00;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ff8b00;
}
.needs-area-content .needs-btn .needs-btn-one:hover {
  background-color: #ffffff;
  color: #ff8b00;
}

/*================================================
Project Section CSS
=================================================*/
.project-section {
  position: relative;
}

.project-title {
  position: relative;
  margin-bottom: 45px;
}
.project-title span {
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  display: block;
  margin-top: -5px;
}
.project-title h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 0 0;
  max-width: 510px;
}
.project-title .project-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.project-title .project-btn .project-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #ff8b00;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ff8b00;
}
.project-title .project-btn .project-btn-one:hover {
  background-color: #ffffff;
  color: #ff8b00;
}

.single-project-item {
  position: relative;
  text-align: left;
  margin-bottom: 30px;
}
.single-project-item .project-content {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  padding: 25px 15px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding: 25px;
}
.single-project-item .project-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 5px;
  background-color: #ff8b00;
  z-index: -1;
  border-radius: 2px;
}
.single-project-item .project-content h3 {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 0px;
  color: #ffffff;
}
.single-project-item .project-content h3 a {
  color: #ffffff;
}
.single-project-item .project-content p {
  color: #fce5ca;
  font-weight: 400;
}
.single-project-item:hover .project-content, .single-project-item .single-project-item:focus .project-content {
  bottom: 10px;
  opacity: 1;
  visibility: visible;
  color: #ffffff;
}

/*================================================
Saying Section CSS
=================================================*/
.saying-section {
  position: relative;
  background-color: #f8f8f8;
}

.saying-title {
  text-align: center;
  margin-bottom: 65px;
}
.saying-title h3 {
  font-size: 38px;
  color: #624272;
  margin: -8px 0 18px 0;
}
.saying-title p {
  font-weight: 400;
  max-width: 750px;
  margin: auto;
}

.saying-slides .saying-single-item {
  position: relative;
  margin-top: 70px;
  transition: 0.5s;
}
.saying-slides .saying-single-item .saying-content-text {
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}
.saying-slides .saying-single-item .saying-content-text h3 {
  font-size: 18px;
  color: #3e435e;
  margin: 0 0 12px 0;
}
.saying-slides .saying-single-item .saying-content-text p {
  color: #827f8d;
  margin: 0 0 0 0;
}
.saying-slides .saying-single-item .saying-info {
  margin-bottom: 16px;
  margin-top: 40px;
  padding-left: 100px;
}
.saying-slides .saying-single-item .saying-info .say-image {
  position: absolute;
  left: 0;
  bottom: -15px;
}
.saying-slides .saying-single-item .saying-info .say-image img {
  display: inline-block;
  width: 80px;
  border-radius: 100px;
}
.saying-slides .saying-single-item .saying-info h4 {
  font-size: 18px;
  color: #3e435e;
  margin: 0 0 2px 0;
}
.saying-slides .saying-single-item .saying-info span {
  color: #827f8d;
  font-size: 15px;
}
.saying-slides .owl-item.active.center .saying-single-item {
  margin-top: 0 !important;
}
.saying-slides.owl-theme .owl-dots {
  line-height: 0.01;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin-top: 5px;
  margin-bottom: 4px;
}
.saying-slides.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #dfdfdf;
  transition: 0.6s;
  border-radius: 50px;
}
.saying-slides.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #0b4d6b;
}
.saying-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: #0b4d6b;
}
.saying-slides.owl-theme .owl-nav {
  margin-top: 30px;
}

/*================================================
Faq Section CSS
=================================================*/
.faq-section {
  position: relative;
}

.faq-title {
  text-align: center;
  margin-bottom: 50px;
}
.faq-title span {
  font-size: 16px;
  color: #ff8b00;
  font-weight: 400;
  display: block;
  margin-top: -5px;
}
.faq-title h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 0 0;
}

.faq-accordion {
  max-width: 790px;
  margin: auto;
}
.faq-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0;
  margin-bottom: 0;
  position: relative;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 12px;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item .accordion-title {
  padding: 15px 20px;
  color: #624272;
  position: relative;
  background: transparent;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  display: block;
  border: 1px solid #767c99;
}
.faq-accordion .accordion .accordion-item .accordion-title i {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #767c99;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
}
.faq-accordion .accordion .accordion-item .accordion-title.active {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}
.faq-accordion .accordion .accordion-item .accordion-title.active i::before {
  content: "\f068";
}
.faq-accordion .accordion .accordion-item .accordion-content {
  display: none;
  background-color: #ffffff;
  margin-bottom: 0;
  padding: 20px 15px;
  font-size: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  z-index: 1;
  position: relative;
}
.faq-accordion .accordion .accordion-item .accordion-content.show {
  display: block;
}

/*================================================
Provide Section CSS
=================================================*/
.provide-section {
  position: relative;
  z-index: 1;
}
.provide-section::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/shape7.png);
}

.provide-area-content h3 {
  font-size: 35px;
  color: #624272;
  margin: 0 0 12px 0;
}
.provide-area-content p {
  font-weight: 400;
  margin: 0 0 0 0;
}
.provide-area-content .provide-form {
  max-width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.provide-area-content .provide-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #f1f1f1;
  border: none;
  height: 64px;
  padding-left: 25px;
  border-radius: 4px;
  padding-top: 0;
  outline: 0;
  color: #343b4e;
}
.provide-area-content .provide-form .input-newsletter::placeholder {
  color: #737b9a;
}
.provide-area-content .provide-form button {
  position: absolute;
  right: 7px;
  top: 7px;
  background-color: #0b4d6b;
  color: #ffffff;
  border: none;
  padding: 14px 30px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  font-size: 15px;
  font-weight: 400;
}
.provide-area-content .provide-form button:hover {
  background-color: #343b4e;
  color: #ffffff;
}
.provide-area-content .provide-form button i {
  margin-left: 5px;
}

/*================================================
Startup Home Area CSS
=================================================*/
.startup-home-area {
  position: relative;
  height: 800px;
  z-index: 1;
  overflow: hidden;
  background-color: #f4f0f1;
}
.startup-home-area .hero-content h1 {
  font-size: 60px;
  color: #343b4e;
  font-weight: bold;
  margin: 20px 0 20px 0;
}
.startup-home-area .hero-content b {
  font-size: 60px;
  color: #002c8c;
  font-weight: bold;
  margin: 20px 0 20px 0;
}
.startup-home-area .hero-content p {
  color: #7d8087;
  font-weight: 500;
  margin: 0 0 0 0;
  font-size: 16px;
}
.startup-home-area .hero-content span {
  display: inline-block;
  font-size: 15px;
  color: #0b4d6b;
  background-color: #ccedfc;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 30px;
  text-transform: capitalize;
}
.startup-home-area .hero-content .startup-btn {
  margin-top: 35px;
}
.startup-home-area .hero-content .startup-btn .startup-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #002c8c;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid #002c8c;
}
.startup-home-area .hero-content .startup-btn .startup-btn-one i {
  position: relative;
  top: 2px;
  padding-left: 6px;
}
.startup-home-area .hero-content .startup-btn .startup-btn-one i::before {
  font-size: 20px;
}
.startup-home-area .hero-content .startup-btn .startup-btn-one:hover {
  background-color: transparent;
  color: #002c8c;
  border: 1px solid #002c8c;
}
.startup-home-area .hero-content .startup-btn .startup-btn-two {
  display: inline-block;
  color: #002c8c;
  border: 1px solid #002c8c;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 5px;
  margin-left: 15px;
}
.startup-home-area .hero-content .startup-btn .startup-btn-two i {
  position: relative;
  top: 2px;
  padding-left: 6px;
}
.startup-home-area .hero-content .startup-btn .startup-btn-two i::before {
  font-size: 20px;
}
.startup-home-area .hero-content .startup-btn .startup-btn-two:hover {
  background-color: #002c8c;
  color: #ffffff;
}
.startup-home-area .startup-image {
  position: relative;
}
.startup-home-area .startup-image img {
  position: absolute;
  top: -222px;
}
.startup-home-area .startup-shape img {
  position: absolute;
  right: 230px;
  bottom: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  width: 850px;
  z-index: -1;
}

/*================================================
Partnar Two Area CSS
=================================================*/
.partnar-two-section {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #002c8c;
}
.partnar-two-section .appreciate {
  max-width: 225px;
  margin-left: auto;
}
.partnar-two-section .appreciate h3 {
  color: #ffffff;
  font-size: 18px;
  line-height: 1.4;
}

/*================================================
Solutions Section CSS
=================================================*/
.solutions-section {
  position: relative;
}
.solutions-section .col-lg-4.col-md-6:nth-child(1) .solutions-area-content {
  position: relative;
}
.solutions-section .col-lg-4.col-md-6:nth-child(1) .solutions-area-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/shape10.png);
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -1;
}
.solutions-section .col-lg-4.col-md-6:nth-child(1) .solutions-area-content .icon::before {
  background-color: #ffebe2;
}
.solutions-section .col-lg-4.col-md-6:nth-child(1) .solutions-area-content .icon i {
  color: #fe5a0e;
}
.solutions-section .col-lg-4.col-md-6:nth-child(2) .solutions-area-content {
  position: relative;
}
.solutions-section .col-lg-4.col-md-6:nth-child(2) .solutions-area-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/shape11.png);
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -1;
}
.solutions-section .col-lg-4.col-md-6:nth-child(2) .solutions-area-content .icon::before {
  background-color: #e0f4ed;
}
.solutions-section .col-lg-4.col-md-6:nth-child(2) .solutions-area-content .icon i {
  color: #00a86f;
}
.solutions-section .col-lg-4.col-md-6:nth-child(3) .solutions-area-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/shape12.png);
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -1;
}
.solutions-section .col-lg-4.col-md-6:nth-child(3) .solutions-area-content .icon::before {
  background-color: #e0e5f1;
}
.solutions-section .col-lg-4.col-md-6:nth-child(3) .solutions-area-content .icon i {
  color: #002c8c;
}

.solutions-title {
  text-align: center;
  margin-bottom: 50px;
  margin-top: -4px;
}
.solutions-title span {
  display: inline-block;
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
  text-transform: capitalize;
}
.solutions-title h3 {
  font-size: 40px;
  margin: 10px 0 12px 0;
}
.solutions-title p {
  max-width: 750px;
  margin: auto;
}

.solutions-area-content {
  position: relative;
  padding: 30px;
  transition: 0.5s;
  box-shadow: 0 13px 27px 0 rgba(197, 197, 197, 0.44);
}
.solutions-area-content .icon {
  position: relative;
}
.solutions-area-content .icon i {
  color: #012d8c;
}
.solutions-area-content .icon i::before {
  font-size: 60px;
  line-height: normal;
}
.solutions-area-content .icon::before {
  content: "";
  position: absolute;
  height: 80px;
  width: 50px;
  background-color: #e0e5f1;
  z-index: -1;
  left: -12px;
  top: -8px;
}
.solutions-area-content h3 {
  font-size: 18px;
  margin: 20px 0 10px 0;
  line-height: 30px;
}
.solutions-area-content p {
  font-weight: 400;
  color: #827f8d;
  margin: 0 0 10px 0;
}
.solutions-area-content a {
  font-size: 16px;
  font-weight: 500;
  color: #827f8d;
  transition: 0.5s;
}
.solutions-area-content a:hover {
  color: #0b4d6b;
  letter-spacing: 1px;
}
.solutions-area-content:hover {
  transform: translateY(-5px);
}

/*================================================
Circulate Section CSS
=================================================*/
.about-section {
  position: relative;
}

.about-content-area span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
}
.about-content-area h3 {
  font-size: 38px;
  color: #343c4e;
  margin: 10px 0 18px 0;
}
.about-content-area p {
  font-weight: 400;
}
.about-content-area .about-btn {
  margin-top: 26px;
}
.about-content-area .about-btn .about-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #012d8c;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #012d8c;
}
.about-content-area .about-btn .about-btn-one:hover {
  background-color: #ffffff;
  color: #012d8c;
}

/*================================================
Co-operative Section CSS
=================================================*/
.co-operative-section {
  position: relative;
  z-index: 1;
  margin-bottom: -30px;
}

.co-operative-title {
  border-right: 1px solid #dfdddf;
  padding-right: 60px;
  margin-bottom: 50px;
  margin-top: -5px;
}
.co-operative-title span {
  color: #002c8c;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
}
.co-operative-title h2 {
  margin-bottom: 0;
  color: #343c4e;
  font-size: 38px;
  font-weight: bold;
}

.co-operative-text {
  padding-left: 40px;
  margin-bottom: 50px;
}
.co-operative-text p {
  color: #666666;
  margin-bottom: 0;
  line-height: 30px;
  font-weight: 400;
}

/*================================================
Counter Section CSS
=================================================*/
.counter-area {
  position: relative;
  background-color: #002c8c;
  padding-top: 130px;
  padding-bottom: 70px;
}
.counter-area .single-fun-facts {
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  transition: 0.5s;
  margin-bottom: 30px;
}
.counter-area .single-fun-facts h3 {
  position: relative;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 700;
}
.counter-area .single-fun-facts h3 .sign-icon {
  display: inline-block;
  font-size: 30px;
  margin-left: 2px;
}
.counter-area .single-fun-facts p {
  line-height: initial;
  color: #ffffff;
  margin: 0 0 0 0;
  font-weight: 600;
  padding-bottom: 0;
  position: relative;
}
.counter-area .single-fun-facts p::before {
  display: none;
}
.counter-area .single-fun-facts:hover {
  transform: translateY(-10px);
}

/*================================================
Grow Section CSS
=================================================*/
.grow-section {
  position: relative;
}

.grow-area-content {
  position: relative;
}
.grow-area-content span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
  text-transform: capitalize;
}
.grow-area-content h3 {
  font-size: 38px;
  color: #343c4e;
  margin: 10px 0 18px 0;
}
.grow-area-content p {
  font-weight: 400;
  margin: 0 0 0 0;
  color: #464b54;
}
.grow-area-content .grow-content-bar {
  position: relative;
  padding-left: 100px;
  margin-top: 45px;
}
.grow-area-content .grow-content-bar span {
  position: absolute;
  left: 0;
  height: 60px;
  width: 80px;
  display: inline-block;
  line-height: 60px;
  background-color: #0b4d6b;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  top: -10px;
}
.grow-area-content .grow-content-bar h3 {
  font-size: 18px;
  color: #595d64;
  font-weight: 500;
  margin: 0 0 0 0;
}
.grow-area-content .grow-content-bar.bg-00a86f span {
  position: absolute;
  left: 0;
  height: 60px;
  width: 80px;
  display: inline-block;
  line-height: 60px;
  background-color: #00a86f;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  top: -10px;
}
.grow-area-content .grow-btn {
  margin-top: 50px;
}
.grow-area-content .grow-btn .grow-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #012d8c;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #012d8c;
}
.grow-area-content .grow-btn .grow-btn-one:hover {
  background-color: #ffffff;
  color: #012d8c;
}
.grow-area-content .grow-btn .grow-btn-two {
  display: inline-block;
  color: #002c8c;
  background-color: transparent;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #012d8c;
  margin-left: 20px;
}
.grow-area-content .grow-btn .grow-btn-two:hover {
  background-color: #002c8c;
  color: #ffffff;
}

/*================================================
Develop Section CSS
=================================================*/
.develop-section {
  position: relative;
}

.develop-content-area span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
  text-transform: capitalize;
}
.develop-content-area h3 {
  font-size: 38px;
  color: #343c4e;
  margin: 10px 0 0 0;
}
.develop-content-area .develop-single-area {
  position: relative;
  margin-top: 45px;
  padding-left: 90px;
}
.develop-content-area .develop-single-area .icon i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #0b4d6b;
  color: #ffffff;
}
.develop-content-area .develop-single-area .icon i::before {
  font-size: 40px;
}
.develop-content-area .develop-single-area h3 {
  font-size: 20px;
  color: #3e435e;
  margin: 0 0 12px 0;
}
.develop-content-area .develop-single-area p {
  color: #827f8d;
  font-weight: 400;
  margin: 0 0 0 0;
}
.develop-content-area .develop-single-area.bg-00a86f .icon i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00a86f;
  color: #ffffff;
}
.develop-content-area .develop-single-area.bg-00a86f .icon i::before {
  font-size: 40px;
}
.develop-content-area .develop-single-area.bg-07328f .icon i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #07328f;
  color: #ffffff;
}
.develop-content-area .develop-single-area.bg-07328f .icon i::before {
  font-size: 40px;
}
.develop-content-area .develop-btn {
  margin-top: 30px;
}
.develop-content-area .develop-btn .develop-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: transparent;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  background-color: #002c8c;
  border: 1px solid #012d8c;
}
.develop-content-area .develop-btn .develop-btn-one:hover {
  background-color: transparent;
  border: 1px solid #012d8c;
  color: #012d8c;
}

/*================================================
Blog Section CSS
=================================================*/
.blog-section {
  position: relative;
}
.blog-section.startup-blog {
  background-color: #fbfcff;
}

.blog-title {
  position: relative;
  margin-bottom: 45px;
  margin-top: -5px;
}
.blog-title span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
  text-transform: capitalize;
}
.blog-title h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 16px 0;
  max-width: 510px;
}
.blog-title p {
  font-weight: 400;
  margin: 0 0 0 0;
  color: #6b6f76;
  max-width: 750px;
}
.blog-title .blog-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.blog-title .blog-btn .blog-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #002c8c;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #002c8c;
}
.blog-title .blog-btn .blog-btn-one:hover {
  background-color: #ffffff;
  color: #002c8c;
}

.blog-item {
  transition: 0.5s;
}
.blog-item .single-blog-item {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-top: none;
  padding: 20px 25px;
  transition: 0.5s;
  position: relative;
  margin-bottom: 30px;
}
.blog-item .single-blog-item .blog-content {
  transition: 0.5s;
  position: relative;
}
.blog-item .single-blog-item .blog-content h3 {
  color: #343846;
  font-size: 20px;
  margin: 0 0px 10px 0;
  line-height: 30px;
  transition: 0.5s;
  position: relative;
}
.blog-item .single-blog-item .blog-content h3::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 60px;
  background-color: #002c8c;
  left: -54px;
  top: 28px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.blog-item .single-blog-item .blog-content p {
  margin: 0;
  color: #6a6c72;
  font-weight: 400;
}
.blog-item .single-blog-item .blog-content a {
  text-decoration: none;
}
.blog-item .single-blog-item .blog-list {
  padding-left: 0;
  margin-bottom: 0;
  transition: 0.5s;
  margin-top: 15px;
  background-color: #f7f7f7;
  padding: 10px 15px;
}
.blog-item .single-blog-item .blog-list li {
  list-style-type: none;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  margin-right: 20px;
  color: #6b6f76;
}
.blog-item .single-blog-item .blog-list li:last-child {
  margin-right: 0;
}
.blog-item .single-blog-item .blog-list li i::before {
  font-size: 14px;
  margin-right: 5px;
}
.blog-item .single-blog-item .blog-list a {
  color: #93908f;
  text-decoration: none;
}
.blog-item .single-blog-item .blog-list i::before {
  font-size: 14px;
  color: #93908f;
  margin-right: 5px;
}
.blog-item:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}
.blog-item:hover .blog-content h3 {
  color: #002c8c;
}
.blog-item:hover {
  transform: translateY(-5px);
}

/*================================================
Subscribe Two Section CSS
=================================================*/
.job-opportunities {
  text-align: center;
  position: relative;
  z-index: 1;
}
.job-opportunities::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: #002c8c;
  z-index: -1;
}
.job-opportunities .section-title {
  max-width: 700px;
  margin: auto;
}
.job-opportunities .section-title span {
  background-color: transparent;
  display: block;
  margin-top: -12px;
  margin-bottom: 5px;
}
.job-opportunities .section-title h3 {
  margin-bottom: 20px;
}
.job-opportunities .grow-btn {
  margin-top: 50px;
  position: relative;
  top: 55px;
}
.job-opportunities .grow-btn .grow-btn-one {
  display: inline-block;
  color: #012d8c;
  background-color: #ffffff;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ffffff;
}
.job-opportunities .grow-btn .grow-btn-one:hover {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.job-opportunities .grow-btn .grow-btn-two {
  display: inline-block;
  color: #ffffff;
  background-color: transparent;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ffffff;
  margin-left: 20px;
}
.job-opportunities .grow-btn .grow-btn-two:hover {
  background-color: #ffffff;
  color: #012d8c;
}

/*================================================
Subscribe Two Section CSS
=================================================*/
#validator-newsletter {
  color: red;
  margin-top: 10px;
}

/*================================================
SEO Agency Home Area CSS
=================================================*/
/*================================================
Visitor Section CSS
=================================================*/
.visitor-section {
  position: relative;
  padding-top: 60px;
}
.visitor-section .col-lg-3.col-md-6:nth-child(1) .visitor-content-area {
  margin-top: 30px;
}
.visitor-section .col-lg-3.col-md-6:nth-child(3) .visitor-content-area {
  margin-top: 30px;
}

.visitor-title {
  margin-bottom: 50px;
}
.visitor-title h2 {
  margin-bottom: 0;
  color: #3f3360;
  font-size: 38px;
  font-weight: bold;
}

.visitor-text {
  margin-bottom: 50px;
}
.visitor-text p {
  color: #827f8d;
  margin-bottom: 0;
  line-height: 30px;
  font-weight: 400;
}

.visitor-content-area {
  position: relative;
  padding: 20px;
  transition: 0.5s;
  box-shadow: 0px 0px 27px 0 rgba(197, 197, 197, 0.44);
  margin-bottom: 30px;
  transition: 0.5s;
}
.visitor-content-area .icon i {
  color: #0b4d6b;
}
.visitor-content-area .icon i::before {
  font-size: 50px;
  line-height: normal;
}
.visitor-content-area h3 {
  font-size: 18px;
  color: #3e435e;
  margin: 14px 0 5px 0;
}
.visitor-content-area p {
  color: #827f8d;
  margin: 0 0 0 0;
}
.visitor-content-area .visitor-shape {
  position: absolute;
  top: 0;
  right: 0;
}
.visitor-content-area.bg-00a86f .icon i {
  color: #00a86f;
}
.visitor-content-area.bg-00a86f .icon i::before {
  font-size: 50px;
  line-height: normal;
}
.visitor-content-area.bg-302353 .icon i {
  color: #302353;
}
.visitor-content-area.bg-302353 .icon i::before {
  font-size: 50px;
  line-height: normal;
}
.visitor-content-area.bg-c53a17 .icon i {
  color: #c53a17;
}
.visitor-content-area.bg-c53a17 .icon i::before {
  font-size: 50px;
  line-height: normal;
}
.visitor-content-area:hover {
  transform: translateY(-30px);
}

/*================================================
Specialty Section CSS
=================================================*/
.specialty-section {
  position: relative;
}

.specialty-content-area span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
}
.specialty-content-area h3 {
  font-size: 38px;
  color: #3f3360;
  margin: 10px 0 14px 0;
}
.specialty-content-area p {
  font-weight: 400;
  margin: 0 0 0 0;
  color: #6b6f76;
}
.specialty-content-area .specialty-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 0px;
  margin-top: 10px;
}
.specialty-content-area .specialty-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  font-weight: 500;
  color: #3e435e;
  font-size: 18px;
  padding-right: 0;
  padding-left: 0;
  margin-top: 20px;
  position: relative;
}
.specialty-content-area .specialty-list i {
  display: inline-block;
  height: 25px;
  width: 32px;
  line-height: 25px;
  background-color: #11023b;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  margin-right: 8px;
  border-radius: 3px 0 3px 3px;
}
.specialty-content-area .specialty-btn {
  margin-top: 40px;
}
.specialty-content-area .specialty-btn .specialty-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #0b4d6b;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #0b4d6b;
}
.specialty-content-area .specialty-btn .specialty-btn-one:hover {
  background-color: #ffffff;
  color: #0b4d6b;
}

/*================================================
Department Section CSS
=================================================*/
.department-area-content span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
}
.department-area-content h3 {
  font-size: 38px;
  color: #3f3360;
  margin: 10px 0 14px 0;
}
.department-area-content p {
  font-weight: 400;
  margin: 0 0 0 0;
  color: #6b6f76;
}
.department-area-content .department-list {
  margin-bottom: 0;
  padding-left: 0;
}
.department-area-content .department-list li {
  list-style-type: none;
  font-weight: 500;
  color: #3e435e;
  font-size: 18px;
  margin-top: 20px;
  position: relative;
}
.department-area-content .department-list i {
  display: inline-block;
  height: 25px;
  width: 32px;
  line-height: 25px;
  background-color: #11023b;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  margin-right: 8px;
  border-radius: 3px 0 3px 3px;
}
.department-area-content .department-btn {
  margin-top: 40px;
}
.department-area-content .department-btn .department-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #0b4d6b;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #0b4d6b;
}
.department-area-content .department-btn .department-btn-one:hover {
  background-color: #ffffff;
  color: #0b4d6b;
}

/*================================================
Success Section CSS
=================================================*/
.success-section {
  background-color: #150346;
  position: relative;
}
.success-section::before {
  content: "";
  position: absolute;
  top: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}
.success-section::after {
  content: "";
  position: absolute;
  bottom: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}

.success-title {
  text-align: center;
  margin-bottom: 50px;
}
.success-title h3 {
  font-size: 38px;
  color: #ffffff;
  margin: 0 0 0 0;
}

.success-area-content {
  position: relative;
  padding-left: 52px;
}
.success-area-content i {
  position: absolute;
  left: 0;
  top: -6px;
  color: #ffffff;
}
.success-area-content i::before {
  font-size: 40px;
  margin-left: 0;
}
.success-area-content h3 {
  font-size: 22px;
  color: #ffffff;
  margin: 0 0 5px 0;
}
.success-area-content span {
  font-size: 16px;
  color: #c8b9f0;
}

/*================================================
Partnar Three Area CSS
=================================================*/
.partnar-three-section {
  position: relative;
  padding-bottom: 0;
}
.partnar-three-section .owl-carousel .owl-item img {
  width: unset;
  margin: auto;
  opacity: 0.5;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.partnar-three-section .owl-carousel .owl-item img:hover {
  opacity: 1;
}

/*================================================
Offer Section CSS
=================================================*/
.offer-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 70px;
}

.offer-title {
  text-align: center;
  margin-bottom: 50px;
}
.offer-title span {
  font-size: 15px;
  color: #3e435e;
  font-weight: 400;
  display: block;
  margin-top: -5px;
  text-transform: capitalize;
}
.offer-title h3 {
  font-size: 36px;
  margin: 5px 0 10px 0;
  color: #3f3360;
}
.offer-title p {
  color: #827f8d;
  font-weight: 400;
  max-width: 750px;
  margin: auto;
}

.products-area-content {
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
  padding: 40px;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.5s;
}
.products-area-content .products-image {
  text-align: center;
}
.products-area-content h3 {
  font-size: 20px;
  color: #3e435e;
  margin: 40px 0 10px 0;
}
.products-area-content p {
  color: #827f8d;
  font-weight: 400;
  margin: 0 0 10px 0;
}
.products-area-content .products-btn-one {
  display: inline-block;
  font-size: 16px;
  color: #fe5a0e;
  font-weight: 500;
  transition: 0.5s;
}
.products-area-content .products-btn-one:hover {
  letter-spacing: 1px;
}
.products-area-content .products-text span {
  position: absolute;
  font-size: 30px;
  color: #827f8d;
  font-weight: 400;
  right: 16px;
  bottom: 4px;
}
.products-area-content:hover {
  transform: translateY(-5px);
}

/*================================================
Increase Section CSS
=================================================*/
.increase-section {
  padding-top: 100px;
  padding-bottom: 70px;
  background-color: #150346;
  position: relative;
  z-index: 1;
}
.increase-section .shape {
  position: absolute;
  top: 150px;
  left: 30px;
  width: 50%;
  z-index: -1;
}
.increase-section::before {
  content: "";
  position: absolute;
  top: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}
.increase-section::after {
  content: "";
  position: absolute;
  bottom: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}

.increase-title {
  position: relative;
  margin-bottom: 60px;
}
.increase-title span {
  font-size: 16px;
  color: #c8b9f0;
  font-weight: 400;
  display: block;
  margin-top: -5px;
  text-transform: capitalize;
}
.increase-title h3 {
  font-size: 38px;
  color: #ffffff;
  margin: 10px 0 16px 0;
  max-width: 510px;
}
.increase-title .increase-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.increase-title .increase-btn .increase-btn-one {
  display: inline-block;
  color: #030749;
  background-color: #ffffff;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #ffffff;
}
.increase-title .increase-btn .increase-btn-one:hover {
  background-color: #0b4d6b;
  color: #ffffff;
  border: 1px solid #0b4d6b;
}

.increase-item {
  position: relative;
}
.increase-item .increase-area-content {
  padding-left: 100px;
  margin-bottom: 30px;
}
.increase-item .increase-area-content img {
  position: absolute;
  left: 16px;
  top: 18px;
  z-index: 1;
}
.increase-item .increase-area-content h3 {
  font-size: 20px;
  color: #d9cdff;
  margin: 0 0 10px 0;
}
.increase-item .increase-area-content p {
  color: #d9cdff;
  margin: 0 0 0 0;
  font-weight: 400;
}
.increase-item .increase-shape img {
  position: absolute;
  left: 0;
  top: 0;
}

/*================================================
Team Section CSS
=================================================*/
.team-section {
  position: relative;
  background-color: #fafafa;
  z-index: 1;
}
.team-section::before {
  content: "";
  position: absolute;
  top: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}
.team-section::after {
  content: "";
  position: absolute;
  bottom: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}
.team-section .shape {
  position: absolute;
  top: 125px;
  left: 0;
  width: 100%;
  right: 0;
  margin: auto;
  z-index: -1;
}

.team-title {
  text-align: center;
  margin-bottom: 50px;
}
.team-title span {
  font-size: 15px;
  color: #3e435e;
  font-weight: 400;
  display: block;
  margin-top: -5px;
}
.team-title h3 {
  font-size: 36px;
  margin: 5px 0 10px 0;
  color: #3f3360;
}
.team-title p {
  color: #827f8d;
  font-weight: 400;
  max-width: 750px;
  margin: auto;
}

.single-team-member {
  text-align: center;
  margin-bottom: 30px;
}
.single-team-member .member-image {
  position: relative;
  border-radius: 50%;
}
.single-team-member .member-image img {
  border-radius: 50%;
}
.single-team-member .member-image .details-btn {
  border-radius: 50%;
}
.single-team-member .member-content {
  margin-top: 20px;
}
.single-team-member .member-content h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.single-team-member .member-content a {
  color: #3e435e;
}
.single-team-member .member-content span {
  display: block;
  color: #827f8d;
  margin-top: 8px;
  font-size: 18px;
}
.single-team-member:hover .member-image::before {
  opacity: 0.6;
  visibility: visible;
}
.single-team-member:hover .member-image .details-btn {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

/*================================================
Request Section CSS
=================================================*/
.request-section {
  position: relative;
}

.request-area {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
}
.request-area h3 {
  font-size: 25px;
  color: #3f3360;
  margin: 0 0 8px 0;
}
.request-area .request-form .form-group {
  margin-bottom: 20px;
}
.request-area .request-form form .form-control {
  height: 55px;
  padding: 10px;
  font-size: 15px;
  color: #0e314c;
  border: 1px solid #f8f8f8;
  border-radius: 0;
  background: #f8f8f8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.request-area .request-form form .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.request-area .request-form form .btn {
  font-weight: 400;
  font-size: 16px;
  border: none;
  padding: 15px 40px;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  text-transform: capitalize;
  transition: 0.5s;
  width: 100%;
}
.request-area .request-form form .btn-primary {
  color: #ffffff;
  background-color: #0b4d6b;
  -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
  box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
  transition: 0.5s;
  border: 1px solid #0b4d6b;
}
.request-area .request-form form .btn-primary:hover {
  background-color: #ffffff;
  color: #0b4d6b;
}

/*================================================
Blog Two Section CSS
=================================================*/
.blog-two-section {
  position: relative;
  padding-bottom: 70px;
}
.blog-two-section .blog-title-two {
  position: relative;
  margin-bottom: 45px;
  text-align: center;
}
.blog-two-section .blog-title-two span {
  font-size: 16px;
  color: #002c8c;
  font-weight: 400;
}
.blog-two-section .blog-title-two h3 {
  font-size: 38px;
  color: #624272;
  margin: 10px 0 0 0;
}
.blog-two-section .blog-title-two .blog-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.blog-two-section .blog-title-two .blog-btn .blog-btn-one {
  display: inline-block;
  color: #ffffff;
  background-color: #002c8c;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 3px;
  border: 1px solid #002c8c;
}
.blog-two-section .blog-title-two .blog-btn .blog-btn-one:hover {
  background-color: #ffffff;
  color: #002c8c;
}
.blog-two-section .blog-item-two {
  transition: 0.5s;
}
.blog-two-section .blog-item-two .single-blog-item {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-top: none;
  padding: 20px 25px;
  transition: 0.5s;
  position: relative;
  margin-bottom: 30px;
}
.blog-two-section .blog-item-two .single-blog-item .blog-content {
  transition: 0.5s;
  position: relative;
}
.blog-two-section .blog-item-two .single-blog-item .blog-content h3 {
  color: #343846;
  font-size: 20px;
  margin: 0 0px 10px 0;
  line-height: 30px;
  transition: 0.5s;
  position: relative;
}
.blog-two-section .blog-item-two .single-blog-item .blog-content p {
  margin: 0;
  color: #6a6c72;
  font-weight: 400;
}
.blog-two-section .blog-item-two .single-blog-item .blog-content a {
  text-decoration: none;
}
.blog-two-section .blog-item-two .single-blog-item .blog-list {
  padding-left: 0;
  margin-bottom: 0;
  transition: 0.5s;
  margin-top: 15px;
  background-color: #f7f7f7;
  padding: 10px 15px;
}
.blog-two-section .blog-item-two .single-blog-item .blog-list li {
  list-style-type: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  color: #6b6f76;
}
.blog-two-section .blog-item-two .single-blog-item .blog-list li:last-child {
  margin-right: 0;
}
.blog-two-section .blog-item-two .single-blog-item .blog-list li i::before {
  font-size: 14px;
  margin-right: 5px;
}
.blog-two-section .blog-item-two .single-blog-item .blog-list a {
  color: #93908f;
  text-decoration: none;
}
.blog-two-section .blog-item-two .single-blog-item .blog-list i::before {
  font-size: 14px;
  color: #93908f;
  margin-right: 5px;
}
.blog-two-section .blog-item-two:hover {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}
.blog-two-section .blog-item-two:hover .blog-content h3 {
  color: #002c8c;
}

/*================================================
Update Section CSS
=================================================*/
.update-section {
  position: relative;
  background-color: #150346;
  z-index: 1;
}
.update-section::before {
  content: "";
  position: absolute;
  top: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}
.update-section::after {
  content: "";
  position: absolute;
  bottom: -47px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 48%;
}
.update-section .shape {
  width: 25%;
  position: absolute;
  left: 100px;
  top: 57px;
  z-index: -1;
}
.update-section .shape-2 {
  width: 20%;
  position: absolute;
  right: 50px;
  top: 57px;
  z-index: -1;
}

.update-title {
  text-align: center;
  margin-bottom: 30px;
}
.update-title h3 {
  font-size: 38px;
  color: #ffffff;
  margin: -8px 0 0 0;
}

.update-form {
  max-width: 630px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.update-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  height: 60px;
  padding-left: 25px;
  border-radius: 5px;
  padding-top: 0;
  outline: 0;
  color: #ffffff;
  border: 1px solid #534677;
}
.update-form .input-newsletter::placeholder {
  color: #737b9a;
}
.update-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fe5a0e;
  color: #ffffff;
  border: none;
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  transition: 0.5s;
  line-height: 45px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}
.update-form button:hover {
  background-color: #ccf6fc;
  color: #0b4d6b;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background-image: url(../../assets/img/page-title-bg.webp);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 1;
}
.page-title-area::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: -1;
}

.page-title-content {
  text-align: center;
  margin-top: 75px;
}
.page-title-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 42px;
  font-weight: 700;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 12px;
  margin-bottom: 0;
}
.page-title-content ul li {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  margin-left: 12px;
  margin-right: 12px;
}
.page-title-content ul li a {
  display: inline-block;
  color: #ffffff;
}
.page-title-content ul li::before {
  content: "";
  position: absolute;
  right: -15px;
  top: 11px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0b4d6b;
}
.page-title-content ul li:last-child::before {
  display: none;
}

/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
  margin-bottom: 60px;
}
.services-details-overview:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
}
.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
}
.services-details-overview .services-details-desc .features-text h4 i {
  font-size: 15px;
  margin-right: 4px;
  color: #0b4d6b;
}
.services-details-overview .services-details-desc .services-details-accordion {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item a {
  text-decoration: none;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
  padding: 15px 20px 15px 51px;
  color: #000000;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: inline-block;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #0b4d6b;
  color: #ffffff;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 13px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content.show {
  display: block;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  margin: 0 3px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 48px;
  color: #343b4e;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 18px;
  font-weight: 700;
}
.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
  background: #0b4d6b;
  color: #ffffff;
  box-shadow: 0 2px 10px 0 #d8dde6;
}

/*================================================
Projects Details Area CSS
=================================================*/
.project-details-image {
  margin-bottom: 30px;
}

.projects-details-desc {
  margin-top: 5px;
}
.projects-details-desc h3 {
  margin-bottom: 13px;
  font-size: 24px;
  font-weight: 700;
}
.projects-details-desc .features-text {
  margin-top: 25px;
  margin-bottom: 25px;
}
.projects-details-desc .features-text h4 {
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600;
}
.projects-details-desc .features-text h4 i {
  font-size: 16px;
  margin-right: 4px;
  color: #0b4d6b;
}
.projects-details-desc .project-details-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 35px;
}
.projects-details-desc .project-details-info .single-info-box {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}
.projects-details-desc .project-details-info .single-info-box h4 {
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 700;
}
.projects-details-desc .project-details-info .single-info-box span {
  display: block;
  color: #737b9a;
  font-size: 15px;
}
.projects-details-desc .project-details-info .single-info-box .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.projects-details-desc .project-details-info .single-info-box .social li {
  display: inline-block;
  margin-right: 8px;
}
.projects-details-desc .project-details-info .single-info-box .social li a {
  color: #737b9a;
  display: inline-block;
}
.projects-details-desc .project-details-info .single-info-box .social li a:hover {
  color: #0b4d6b;
  transform: translateY(-5px);
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-contact-section {
  position: relative;
}
.faq-contact-section .faq-title {
  text-align: center;
  margin-bottom: 50px;
}
.faq-contact-section .faq-title h2 {
  font-size: 38px;
  color: #624272;
  margin: 0 0 10px 0;
}
.faq-contact-section .faq-title p {
  font-weight: 400;
  max-width: 750px;
  margin: auto;
}

.faq-contact-form {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}
.faq-contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 50px;
  font-size: 15px;
}
.faq-contact-form form textarea.form-control {
  height: 150px;
  padding-top: 15px;
  line-height: initial;
}
.faq-contact-form form .faq-form-btn {
  margin-top: 20px;
}

/*================================================
Login In Style CSS
=================================================*/
.login-form {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}
.login-form .login-title {
  text-align: center;
  margin-bottom: 30px;
}
.login-form .login-title h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #343846;
}
.login-form .login-title p {
  margin-bottom: 30px;
}
.login-form form .form-control {
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #ffffff;
  padding-left: 20px;
  background: transparent;
}
.login-form form .form-control:focus {
  border-color: #0b4d6b;
  outline: 0;
  box-shadow: none;
}
.login-form form .form-control::placeholder {
  color: #666666;
}
.login-form form .form-check {
  margin-top: 18px;
}
.login-form form .form-check .form-check-label {
  color: #666666;
  position: relative;
  top: -2px;
}
.login-form form .form-check-input {
  margin-top: 3px;
}
.login-form form .send-btn {
  margin-top: 20px;
}
.login-form form .send-btn .default-btn-one {
  display: inline-block;
  padding: 12px 30px;
  background: #0b4d6b;
  border: 1px solid #0b4d6b;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.6s;
}
.login-form form .send-btn .default-btn-one:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #0b4d6b;
}
.login-form form span {
  display: block;
  color: #777777;
  position: absolute;
  bottom: 22px;
  right: 15px;
}
.login-form form span a {
  color: #0b4d6b;
}
.login-form .forgot-password {
  margin-bottom: 0;
  margin-top: -25px;
}
.login-form .forgot-password a {
  color: #777777;
}
.login-form .forgot-password a:hover {
  color: #0b4d6b;
}

/*================================================
Sign Up Style CSS
=================================================*/
.signup-form {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}
.signup-form h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form form .form-control {
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #ffffff;
  padding-left: 20px;
  background: transparent;
}
.signup-form form .form-control:focus {
  border-color: #0b4d6b;
  outline: 0;
  box-shadow: none;
}
.signup-form form .form-control::placeholder {
  color: #666666;
}
.signup-form form .form-check {
  margin-top: 18px;
}
.signup-form form .form-check .form-check-label {
  color: #666666;
  position: relative;
  top: -2px;
}
.signup-form form .send-btn {
  margin-top: 20px;
}
.signup-form form .send-btn .default-btn-one {
  display: inline-block;
  padding: 12px 30px;
  background: #0b4d6b;
  border: 1px solid #0b4d6b;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.6s;
}
.signup-form form .send-btn .default-btn-one:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #0b4d6b;
}
.signup-form form span {
  display: block;
  color: #666666;
  position: absolute;
  bottom: 35px;
  right: 15px;
}
.signup-form form span a {
  color: #0b4d6b;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  font-size: 35px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 18px;
}
.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
  line-height: 30px;
}
.error-content .default-btn-one {
  display: inline-block;
  padding: 12px 30px;
  background: #0b4d6b;
  border: 1px solid #0b4d6b;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.6s;
}
.error-content .default-btn-one:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #0b4d6b;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-image: url(../../assets/img/page-title-bg.webp);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-area::before {
  z-index: -1;
  background: #000000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.8;
}
.coming-soon-area .social-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  bottom: 30px;
}
.coming-soon-area .social-list li {
  display: inline-block;
}
.coming-soon-area .social-list li.list-heading {
  display: block;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.coming-soon-area .social-list li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #f45a43;
  border: 1px solid #f45a43;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
}
.coming-soon-area .social-list li a:hover {
  background-color: transparent;
  color: #f45a43;
  transition: 0.6s;
  -webkit-transition: 0.5s;
}

.coming-soon-content {
  text-align: center;
  max-width: 820px;
  margin: -80px auto 0;
}
.coming-soon-content h1 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 46px;
  font-weight: 700;
}
.coming-soon-content p {
  color: #ffffff;
  margin: 0 auto;
  max-width: 620px;
  line-height: 30px;
}
.coming-soon-content form {
  position: relative;
  margin: 35px auto 55px;
  max-width: 520px;
}
.coming-soon-content form .email-input {
  display: block;
  width: 100%;
  height: 56px;
  border: none;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  padding: 15px 25px;
  outline: 0 !important;
  background: #F1F2F3;
}
.coming-soon-content form .submit-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 50px;
  background: #f45a43;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  width: 130px;
  outline: 0 !important;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.coming-soon-content form .submit-btn:hover, .coming-soon-content form .submit-btn:focus {
  background-color: #ff3547;
}
.coming-soon-content #timer div {
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  color: #ff3547;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
  margin: 0 10px;
  padding-top: 18px;
  font-size: 40px;
  font-weight: 700;
}
.coming-soon-content #timer div span {
  display: block;
  margin-top: -4px;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
}

/*================================================
Contact Section CSS
=================================================*/
.contact-area .contact-form {
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.contact-area .contact-form form {
  text-align: center;
}
.contact-area .contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 57px;
  color: #0b4d6b;
  border: 1px solid #ffffff;
}
.contact-area .contact-form form .form-control:focus {
  border: 1px solid #0b4d6b;
}
.contact-area .contact-form form .form-group {
  text-align: left;
  margin-bottom: 25px;
}
.contact-area .contact-form form textarea.form-control {
  height: 250px;
  padding-top: 15px;
}
.contact-area .contact-form .default-btn {
  cursor: pointer;
  margin-top: 10px;
}
.contact-area .contact-form .text-danger {
  color: #dc3545 !important;
  margin-bottom: 0 !important;
  margin-top: 20px !important;
}
.contact-area .contact-form .list-unstyled {
  margin-top: 10px;
  color: red;
}

.contact-box {
  padding-top: 100px;
  padding-bottom: 35px;
}

.single-contact-box {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
}
.single-contact-box i {
  line-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px dashed #0b4d6b;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
  color: #0b4d6b;
  transition: all 0.5s;
  margin-bottom: 20px;
}
.single-contact-box:hover i {
  background-color: #0b4d6b;
  color: #ffffff;
}
.single-contact-box .content-title h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.single-contact-box .content-title a {
  display: block;
  font-size: 16px;
  color: #747171;
  margin-bottom: 3px;
}
.single-contact-box .content-title a:hover {
  color: #0b4d6b;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-content {
  margin-top: 30px;
}
.blog-details-desc .article-content .entry-meta {
  margin-bottom: -8px;
}
.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #343b4e;
  margin-right: 21px;
}
.blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: #343b4e;
  font-weight: 500;
}
.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #737b9a;
}
.blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #0b4d6b;
}
.blog-details-desc .article-content .entry-meta ul li i {
  color: #0b4d6b;
  margin-right: 2px;
}
.blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 12px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #0b4d6b;
}
.blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details-desc .article-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 24px;
  font-weight: 600;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}
.blog-details-desc .article-content .features-list li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 34px;
  color: #737b9a;
}
.blog-details-desc .article-content .features-list li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 40px;
  background-color: #faf5f5;
  color: #0b4d6b;
  transition: 0.5s;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: -2px;
}
.blog-details-desc .article-content .features-list li:hover i {
  background-color: #0b4d6b;
  color: #ffffff;
}
.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}
.blog-details-desc .article-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}
.blog-details-desc .article-footer .article-tags {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  color: #737b9a;
}
.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #343b4e;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #737b9a;
}
.blog-details-desc .article-footer .article-tags a:hover {
  color: #0b4d6b;
}
.blog-details-desc .article-footer .article-share {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #737b9a;
}
.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: #0b4d6b;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #eeeef0;
  text-align: center;
  font-size: 12px;
  margin-left: 5px;
}
.blog-details-desc .article-footer .article-share .social li a:hover {
  color: #ffffff;
  background-color: #0b4d6b;
  transform: translateY(-2px);
}
.blog-details-desc .post-navigation {
  margin-top: 30px;
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p, .blockquote p {
  color: #343b4e;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 24px !important;
}
blockquote cite, .blockquote cite {
  display: none;
}
blockquote::before, .blockquote::before {
  color: #efefef;
  content: "\f10e";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "Font Awesome 5 Free";
  font-size: 140px;
  font-weight: 900;
}
blockquote::after, .blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #0b4d6b;
  margin-top: 20px;
  margin-bottom: 20px;
}

.post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.post-navigation .navigation-links {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.post-navigation .navigation-links .nav-previous {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.post-navigation .navigation-links .nav-previous a i {
  margin-right: 2px;
  transition: 0.5s;
}
.post-navigation .navigation-links .nav-previous a:hover i {
  margin-right: 0;
}
.post-navigation .navigation-links .nav-next {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.post-navigation .navigation-links .nav-next a i {
  margin-left: 2px;
  transition: 0.5s;
}
.post-navigation .navigation-links .nav-next a:hover i {
  margin-left: 0;
}
.post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 600;
}

.comments-area {
  padding: 25px;
  margin-top: 30px;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: #ffffff;
}
.comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 22px;
  font-weight: 600;
}
.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  color: #343b4e;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #343b4e;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
}
.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #0b4d6b;
  border-color: #0b4d6b;
}
.comments-area .comment-author {
  font-size: 17px;
  margin-bottom: 0.1em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}
.comments-area .comment-author .fn {
  font-weight: 600;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  margin-bottom: 0.8em;
  color: #737b9a;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}
.comments-area .comment-metadata a {
  color: #737b9a;
}
.comments-area .comment-metadata a:hover {
  color: #0b4d6b;
}
.comments-area .comment-respond {
  margin-top: 30px;
}
.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  position: relative;
  font-size: 20px;
  font-weight: 600;
}
.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
  margin-bottom: 0;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-notes .required {
  color: red;
}
.comments-area .comment-respond .comment-form-comment {
  margin-top: 20px;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond label {
  display: block;
  font-weight: 600;
  color: #343b4e;
  margin-bottom: 5px;
}
.comments-area .comment-respond input[type=date], .comments-area .comment-respond input[type=time], .comments-area .comment-respond input[type=datetime-local], .comments-area .comment-respond input[type=week], .comments-area .comment-respond input[type=month], .comments-area .comment-respond input[type=text], .comments-area .comment-respond input[type=email], .comments-area .comment-respond input[type=url], .comments-area .comment-respond input[type=password], .comments-area .comment-respond input[type=search], .comments-area .comment-respond input[type=tel], .comments-area .comment-respond input[type=number], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.comments-area .comment-respond input[type=date]:focus, .comments-area .comment-respond input[type=time]:focus, .comments-area .comment-respond input[type=datetime-local]:focus, .comments-area .comment-respond input[type=week]:focus, .comments-area .comment-respond input[type=month]:focus, .comments-area .comment-respond input[type=text]:focus, .comments-area .comment-respond input[type=email]:focus, .comments-area .comment-respond input[type=url]:focus, .comments-area .comment-respond input[type=password]:focus, .comments-area .comment-respond input[type=search]:focus, .comments-area .comment-respond input[type=tel]:focus, .comments-area .comment-respond input[type=number]:focus, .comments-area .comment-respond textarea:focus {
  border-color: #0b4d6b;
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 4px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #737b9a;
  font-weight: normal;
  position: relative;
  top: -2px;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background: #0b4d6b;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.5s;
  font-weight: 600;
  font-size: 14px;
}
.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #343b4e;
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 35px;
}
.widget-area .widget:first-child {
  margin-top: 0;
}
.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  font-size: 22px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  background: #0b4d6b;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.widget-area .widget_search {
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  background-color: #ffffff;
  padding: 15px;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: 0.5s;
}
.widget-area .widget_search form .search-field:focus {
  border-color: #0b4d6b;
}
.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #ffffff;
  background-color: #0b4d6b;
  transition: 0.5s;
}
.widget-area .widget_search form button:hover {
  background-color: #343b4e;
  color: #ffffff;
}
.widget-area .widget_recent_entries ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #343b4e;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15.5px;
}
.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_entries ul li::before {
  background: #0b4d6b;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
}
.widget-area .widget_recent_entries ul li a {
  display: inline-block;
  color: #343b4e;
}
.widget-area .widget_recent_entries ul li a:hover {
  color: #0b4d6b;
}
.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #737b9a;
  margin-top: 4px;
}
.widget-area .widget_recent_comments ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: #737b9a;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15.5px;
}
.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_comments ul li::before {
  background: #0b4d6b;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
}
.widget-area .widget_recent_comments ul li a {
  display: inline-block;
  color: #343b4e;
}
.widget-area .widget_recent_comments ul li a:hover {
  color: #0b4d6b;
}
.widget-area .widget_archive ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  color: #343b4e;
  font-size: 15.5px;
  font-weight: 400;
}
.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_archive ul li::before {
  background: #0b4d6b;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}
.widget-area .widget_archive ul li a {
  display: inline-block;
  color: #343b4e;
}
.widget-area .widget_archive ul li a:hover {
  color: #0b4d6b;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: #737b9a;
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 400;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: #0b4d6b;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: #343b4e;
  display: block;
}
.widget-area .widget_categories ul li a:hover {
  color: #0b4d6b;
}
.widget-area .widget_categories ul li .post-count {
  float: right;
}
.widget-area .widget_meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  color: #343b4e;
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 400;
}
.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_meta ul li::before {
  background: #0b4d6b;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}
.widget-area .widget_meta ul li a {
  display: inline-block;
  color: #343b4e;
}
.widget-area .widget_meta ul li a:hover {
  color: #0b4d6b;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}
.widget-area .tagcloud a {
  display: inline-block;
  color: #343b4e;
  font-weight: 400;
  font-size: 14.5px !important;
  padding: 6px 13px;
  border: 1px dashed #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #0b4d6b;
  border-color: #0b4d6b;
}
.widget-area .widget_event_details ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_event_details ul li {
  border-bottom: 1px solid #eeeeee;
  color: #888f96;
  padding-bottom: 10px;
  padding-top: 10px;
}
.widget-area .widget_event_details ul li:first-child {
  padding-top: 0;
}
.widget-area .widget_event_details ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.widget-area .widget_event_details ul li span {
  display: inline-block;
  color: #343b4e;
  font-weight: 400;
}
.widget-area .widget_event_details ul li a {
  display: inline-block;
  color: #888f96;
}
.widget-area .widget_event_details ul li a:hover {
  color: #0b4d6b;
}

/*================================================
Shop Area CSS
=================================================*/
.woocommerce-topbar {
  margin-bottom: 40px;
}
.woocommerce-topbar .woocommerce-topbar-ordering {
  text-align: right;
}
.woocommerce-topbar .woocommerce-topbar-ordering .nice-select {
  background-color: #ffffff;
  color: #666666;
  border-radius: 0;
  border: none;
  box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  float: unset;
  height: unset;
  line-height: initial;
  padding: 17px 35px 13px 20px;
  font-size: 16px;
}
.woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list {
  background-color: #ffffff;
  box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list .option {
  transition: 0.6s;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list .option:hover {
  background-color: #0b4d6b !important;
  color: #ffffff;
}
.woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 700;
}
.woocommerce-topbar .woocommerce-topbar-ordering .nice-select:after {
  right: 20px;
}

.single-product-box {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
}
.single-product-box .product-image {
  overflow: hidden;
  position: relative;
  text-align: left;
}
.single-product-box .product-image a img {
  transition: 0.6s;
}
.single-product-box .product-image .add-to-cart-btn {
  position: absolute;
  bottom: -46px;
  left: 0;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  padding: 18px 20px 13px;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}
.single-product-box .product-image .add-to-cart-btn i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.single-product-box .product-image .add-to-cart-btn:hover {
  background-color: #0b4d6b;
  color: #ffffff;
}
.single-product-box .product-image .sale-btn {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: #0b4d6b;
  color: #ffffff;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
.single-product-box .product-content {
  padding: 30px 25px;
}
.single-product-box .product-content h3 {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}
.single-product-box .product-content h3 a {
  display: inline-block;
  color: #0b4d6b;
  text-decoration: none;
}
.single-product-box .product-content .price {
  color: #000000;
  font-weight: 600;
  font-size: 15px;
}
.single-product-box .product-content .price .old {
  text-decoration: line-through;
  color: #828893;
  font-size: 14px;
}
.single-product-box .product-content .rating {
  margin-top: 12px;
  color: #ffb708;
  font-size: 15px;
}
.single-product-box .product-content .rating i {
  margin-right: -1px;
}
.single-product-box:hover .product-image .add-to-cart-btn {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.6s;
  text-decoration: none;
}

/*================================================
Product Details Area CSS
=================================================*/
.product-details-desc h3 {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
}
.product-details-desc .price {
  margin-bottom: 10px;
  color: #000000;
  font-size: 15px;
  font-weight: 600;
}
.product-details-desc .price .old-price {
  text-decoration: line-through;
  color: #828893;
}
.product-details-desc .product-review {
  margin-bottom: 15px;
}
.product-details-desc .product-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 14px;
}
.product-details-desc .product-review .rating i {
  color: #ffba0a;
}
.product-details-desc .product-review .rating-count {
  display: inline-block;
  color: #000000;
  border-bottom: 1px solid #000000;
  line-height: initial;
}
.product-details-desc .product-review .rating-count:hover {
  color: #0b4d6b;
  border-color: #0b4d6b;
  text-decoration: none;
}
.product-details-desc .product-add-to-cart {
  margin-top: 20px;
}
.product-details-desc .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.product-details-desc .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 50px;
  height: 100%;
  line-height: 55px;
  transition: 0.6s;
}
.product-details-desc .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
}
.product-details-desc .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
}
.product-details-desc .product-add-to-cart .input-counter span:hover {
  color: #0b4d6b;
}
.product-details-desc .product-add-to-cart .input-counter input {
  height: 50px;
  color: #000000;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}
.product-details-desc .product-add-to-cart .input-counter input::placeholder {
  color: #000000;
}
.product-details-desc .product-add-to-cart .default-btn {
  position: relative;
  border: none;
  padding: 12px 30px;
  background-color: #0b4d6b;
  color: #ffffff;
  border: 1px solid #0b4d6b;
}
.product-details-desc .product-add-to-cart .default-btn i {
  margin-right: 2px;
}
.product-details-desc .product-add-to-cart .default-btn:hover {
  background-color: #ffffff;
  color: #0b4d6b;
  transition: 0.6s;
}
.product-details-desc .buy-checkbox-btn {
  margin-top: 20px;
}
.product-details-desc .buy-checkbox-btn input {
  display: none;
}
.product-details-desc .buy-checkbox-btn .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.product-details-desc .buy-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.product-details-desc .buy-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #ebebeb;
  transition: all 0.2s ease;
  transition: 0.6s;
}
.product-details-desc .buy-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
  transition: 0.6s;
}
.product-details-desc .buy-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #000000;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition: 0.6s;
}
.product-details-desc .buy-checkbox-btn .cbx span:last-child {
  position: relative;
  top: 2px;
  padding-left: 4px;
  color: #666666;
}
.product-details-desc .buy-checkbox-btn .cbx:hover span:first-child {
  border-color: #0b4d6b;
}
.product-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child {
  background: #0b4d6b;
  border-color: #0b4d6b;
  animation: wave 0.4s ease;
}
.product-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.product-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}
.product-details-desc .buy-checkbox-btn .item:not(:first-child) {
  margin-top: 15px;
}
.product-details-desc .buy-checkbox-btn .btn-light {
  background-color: #f2f2f2;
  border: none;
  padding: 13px 25px 10px 25px;
  transition: 0.6s;
  font-weight: 600;
  display: block;
  width: 100%;
}
.product-details-desc .buy-checkbox-btn .btn-light:hover {
  background-color: #0b4d6b;
  color: #ffffff;
}
.product-details-desc .custom-payment-options {
  margin-top: 20px;
}
.product-details-desc .custom-payment-options span {
  display: block;
  color: #666666;
  margin-bottom: 8px;
}
.product-details-desc .custom-payment-options .payment-methods a {
  display: inline-block;
}
.product-details-desc .custom-payment-options .payment-methods a img {
  width: 40px;
}

.products-details-tab {
  margin-top: 50px;
}
.products-details-tab .tabs {
  list-style-type: none;
  margin-bottom: -1px;
  padding-left: 0;
}
.products-details-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 5px;
}
.products-details-tab .tabs li a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: #343b4e;
  border: 1px dashed #eeeeee;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 15px;
}
.products-details-tab .tabs li a:hover, .products-details-tab .tabs li a:focus {
  color: #ffffff;
  background-color: #0b4d6b;
  border-color: #0b4d6b;
}
.products-details-tab .tabs li.current a {
  color: #ffffff;
  background-color: #0b4d6b;
  border-color: #0b4d6b;
}
.products-details-tab .tabs li:last-child {
  margin-right: 0;
}
.products-details-tab .tab_content {
  border: 1px dashed #eeeeee;
  padding: 30px;
}
.products-details-tab .tab_content .tabs_item {
  display: none;
}
.products-details-tab .tab_content .tabs_item:first-child {
  display: block;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content p {
  margin-bottom: 20px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content p:last-child {
  margin-bottom: 0;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 10px 15px 7px;
  color: #666666;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li:last-child {
  border-bottom: 1px solid #eeeeee;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
  display: inline-block;
  width: 30%;
  color: #343b4e;
  font-weight: 700;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
  position: relative;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
  color: #ffba0a;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ebebeb;
  font-size: 14px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 14px 25px;
  background-color: #0b4d6b;
  color: #ffffff;
  transition: 0.6s;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn:hover {
  background-color: #ffffff;
  color: #0b4d6b;
  text-decoration: none;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
  color: #ffba0a;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  font-size: 14px;
  color: #ebebeb;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 13px;
  display: block;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span strong {
  font-weight: 600;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: #666666;
  top: 40px;
  text-decoration: underline;
  font-weight: 500;
  font-size: 15px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: #0b4d6b;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
  margin-top: 30px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form h3 {
  margin-bottom: 20px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group {
  margin-bottom: 15px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
  height: 55px;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  border: none;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea.form-control {
  padding-top: 15px;
  height: auto;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .default-btn {
  border: none;
  margin-top: 10px;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  padding: 14px 25px;
  background-color: #0b4d6b;
  color: #ffffff;
  border: 1px solid #0b4d6b;
  text-transform: uppercase;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .default-btn:hover {
  background-color: #ffffff;
  color: #0b4d6b;
  transition: 0.6s;
}

.related-products {
  margin-top: 70px;
  text-align: center;
}
.related-products .products-title {
  margin-bottom: 10px;
  text-align: center;
}
.related-products .products-title span {
  font-size: 14px;
  color: #0b4d6b;
}
.related-products .products-title h2 {
  font-size: 30px;
  color: #343b4e;
  margin: 10px 0 0 0;
}
.related-products .single-product-box {
  margin-bottom: 0;
  margin-top: 30px;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}
.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 0 0 15px;
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  font-weight: 700;
}
.cart-table table tbody tr td {
  vertical-align: middle;
  color: 0.6s;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
}
.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}
.cart-table table tbody tr td.product-thumbnail a img {
  width: 80px;
}
.cart-table table tbody tr td.product-name a {
  color: #666666;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
}
.cart-table table tbody tr td.product-name a:hover {
  color: #0b4d6b;
}
.cart-table table tbody tr td.product-subtotal .remove {
  color: #0b4d6b;
  float: right;
  position: relative;
  top: -1px;
}
.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 48px;
  transition: 0.6s;
}
.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}
.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}
.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: #0b4d6b;
}
.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #0b4d6b;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  outline: 0;
  box-shadow: none;
  font-size: 17px;
  font-weight: 600;
}
.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #0b4d6b;
}
.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-buttons {
  margin-top: 30px;
}
.cart-buttons .shopping-coupon-code {
  position: relative;
  max-width: 530px;
}
.cart-buttons .shopping-coupon-code .form-control {
  height: 53px;
  outline: 0;
  box-shadow: none;
  border: 1px solid #0b4d6b;
}
.cart-buttons .shopping-coupon-code button {
  position: absolute;
  right: 0;
  top: 0;
  height: 53px;
  background: #0b4d6b;
  color: #ffffff;
  border: none;
  padding: 0 25px;
  line-height: 54px;
  font-weight: 600;
  outline: 0;
  transition: 0.6s;
  outline: 0;
  box-shadow: none;
}
.cart-buttons .shopping-coupon-code button:hover {
  background-color: #3a60cd;
  color: #ffffff;
}
.cart-buttons .default-btn {
  padding: 14px 30px;
  background-color: #0b4d6b;
  color: #ffffff;
  border: 1px solid #0b4d6b;
  text-decoration: none;
  display: inline-block;
}
.cart-buttons .default-btn:hover {
  background-color: #ffffff;
  color: #0b4d6b;
  transition: 0.6s;
}

.cart-totals {
  background: #ffffff;
  padding: 40px;
  max-width: 620px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.cart-totals h3 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
}
.cart-totals ul {
  padding-left: 0;
  margin: 0 0 25px;
  list-style-type: none;
}
.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 10px 15px;
  color: #343b4e;
  overflow: hidden;
  font-weight: 600;
}
.cart-totals ul li:first-child {
  border-bottom: none;
}
.cart-totals ul li:last-child {
  border-top: none;
}
.cart-totals ul li b {
  font-weight: 500;
}
.cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}
.cart-totals .default-btn {
  padding: 14px 30px;
  background-color: #0b4d6b;
  color: #ffffff;
  border: 1px solid #0b4d6b;
  text-decoration: none;
  display: inline-block;
}
.cart-totals .default-btn:hover {
  background-color: #ffffff;
  color: #0b4d6b;
  transition: 0.6s;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 18px 20px 15px;
  margin-bottom: 65px;
  border-top: 3px solid #343b4e;
  position: relative;
}
.user-actions::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -35px;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}
.user-actions i {
  color: #343b4e;
  margin-right: 2px;
}
.user-actions span {
  display: inline-block;
  font-weight: 400;
  color: #343b4e;
  font-size: 16px;
}
.user-actions span a {
  display: inline-block;
  color: #343b4e;
  text-decoration: none;
}
.user-actions span a:hover, .user-actions span a:focus {
  color: #0b4d6b;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}
.billing-details .title::before {
  content: "";
  position: absolute;
  background: #0b4d6b;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.billing-details .form-group {
  margin-bottom: 25px;
}
.billing-details .form-group label {
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 400;
}
.billing-details .form-group label .required {
  color: #343b4e;
}
.billing-details .form-group .nice-select {
  float: unset;
  line-height: 53px;
  color: #666666;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
}
.billing-details .form-group .nice-select .list {
  background-color: #ffffff;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.billing-details .form-group .nice-select .list .option {
  transition: 0.6s;
  padding-left: 20px;
  padding-right: 20px;
}
.billing-details .form-group .nice-select .list .option:hover {
  background-color: #0b4d6b !important;
  color: #ffffff;
}
.billing-details .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 600;
}
.billing-details .form-group .nice-select:after {
  right: 20px;
}
.billing-details .form-group .form-control {
  height: 50px;
  outline: 0;
  box-shadow: none;
}
.billing-details .form-group .form-control:focus {
  border-color: #0b4d6b;
  outline: 0;
  box-shadow: none;
}
.billing-details .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}
.billing-details .form-check {
  margin-bottom: 20px;
}
.billing-details .form-check .form-check-label {
  color: #343b4e;
}
.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 1px;
  font-weight: 500;
}
.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}
.order-details .title::before {
  content: "";
  position: absolute;
  background: #0b4d6b;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.order-details .order-table table {
  margin-bottom: 0;
}
.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  text-transform: uppercase;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}
.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  border-color: #eaedff;
  font-size: 15.5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 13px;
}
.order-details .order-table table tbody tr td.product-name a {
  color: #666666;
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
}
.order-details .order-table table tbody tr td.product-name a:hover {
  color: #0b4d6b;
}
.order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
  color: #666666;
  font-weight: 500;
}
.order-details .order-table table tbody tr td.shipping-price, .order-details .order-table table tbody tr td.order-subtotal-price, .order-details .order-table table tbody tr td.product-subtotal {
  font-weight: 600;
}
.order-details .payment-box {
  background-color: #ffffff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding: 30px;
}
.order-details .payment-box .payment-method p [type=radio]:checked, .order-details .payment-box .payment-method p [type=radio]:not(:checked) {
  display: none;
}
.order-details .payment-box .payment-method p [type=radio]:checked + label, .order-details .payment-box .payment-method p [type=radio]:not(:checked) + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: #343b4e;
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
}
.order-details .payment-box .payment-method p [type=radio]:checked + label::before, .order-details .payment-box .payment-method p [type=radio]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}
.order-details .payment-box .payment-method p [type=radio]:checked + label::after, .order-details .payment-box .payment-method p [type=radio]:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0b4d6b;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  transition: 0.6s;
}
.order-details .payment-box .payment-method p [type=radio]:not(:checked) + label::after {
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}
.order-details .payment-box .payment-method p [type=radio]:checked + label::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.order-details .payment-box .default-btn {
  padding: 14px 30px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #343b4e;
  background-color: #ffffff;
  transition: 0.6s;
}
.order-details .payment-box .default-btn:hover {
  background-color: #0b4d6b;
  color: #ffffff;
}
.order-details .payment-box .default-btn.order-btn {
  display: block;
  margin-top: 25px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}

/*================================================
Footer Section CSS
=================================================*/
.footer-section {
  background-image: url(../../assets/img/footer-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 100px;
}
.footer-section::before {
  z-index: -1;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0.9;
  right: 0;
}
.footer-section.marketing-footer {
  background-color: #391343;
  background-image: unset;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.footer-section.marketing-footer::before {
  display: none;
}
.footer-section.marketing-footer::after {
  content: "";
  position: absolute;
  top: -80px;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/shape8.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}
.footer-section.marketing-footer .footer-area-content p {
  color: #d8c7e2;
}
.footer-section.marketing-footer .single-footer-widget h3 {
  color: #ffffff;
}
.footer-section.marketing-footer .single-footer-widget .footer-quick-links.address-link a {
  color: #d8c7e2;
  position: relative;
  padding-left: 40px;
}
.footer-section.marketing-footer .single-footer-widget .footer-quick-links.address-link a i {
  position: absolute;
  left: 0;
  top: 0;
  color: #d8c7e2;
}
.footer-section.marketing-footer .single-footer-widget .footer-quick-links.address-link a:hover {
  color: #0b4d6b;
  letter-spacing: normal;
}
.footer-section.marketing-footer .single-footer-widget .footer-quick-links a {
  color: #d8c7e2;
}
.footer-section.marketing-footer .single-footer-widget .footer-quick-links a:hover {
  color: #0b4d6b;
  letter-spacing: 1px;
}
.footer-section.marketing-footer .single-footer-widget .social-list li.list-heading {
  color: #d8c7e2;
}
.footer-section.marketing-footer .single-footer-widget .social-list li a {
  border: 1px solid #d8c7e2;
  color: #d8c7e2;
}
.footer-section.marketing-footer .single-footer-widget .social-list li a:hover {
  background-color: #0b4d6b;
  border: 1px solid #0b4d6b;
  color: #ffffff;
}
.footer-section.marketing-footer .copyright-area p {
  color: #d8c7e2;
}
.footer-section.marketing-footer .copyright-area p a {
  color: #d8c7e2;
}
.footer-section.marketing-footer .copyright-area ul li a {
  color: #d8c7e2;
}
.footer-section.startup-footer {
  background-image: unset;
  position: relative;
}
.footer-section.startup-footer::before {
  display: none;
}
.footer-section.startup-footer .footer-area-content p {
  color: #827f8d;
}
.footer-section.startup-footer .footer-area-content .default-btn {
  margin-top: 30px;
  background-color: #002c8c;
  color: #ffffff;
  border: 1px solid #002c8c;
}
.footer-section.startup-footer .footer-area-content .default-btn:hover {
  border: 1px solid #002c8c;
  color: #002c8c;
  background-color: transparent;
}
.footer-section.startup-footer .single-footer-widget .footer-quick-links.address-link a {
  color: #827f8d;
  position: relative;
  padding-left: 40px;
}
.footer-section.startup-footer .single-footer-widget .footer-quick-links.address-link a i {
  position: absolute;
  left: 0;
  top: 0;
  color: #827f8d;
  transition: all 0.5s;
}
.footer-section.startup-footer .single-footer-widget .footer-quick-links.address-link a:hover {
  color: #0b4d6b;
  letter-spacing: normal;
}
.footer-section.startup-footer .single-footer-widget .footer-quick-links.address-link a:hover i {
  color: #ffffff;
}
.footer-section.startup-footer .single-footer-widget .footer-quick-links a {
  color: #827f8d;
}
.footer-section.startup-footer .single-footer-widget .footer-quick-links a:hover {
  color: #0b4d6b;
  letter-spacing: 1px;
}
.footer-section.startup-footer .single-footer-widget .social-list li.list-heading {
  color: #827f8d;
}
.footer-section.startup-footer .single-footer-widget .social-list li a {
  border: 1px solid #827f8d;
  color: #827f8d;
}
.footer-section.startup-footer .single-footer-widget .social-list li a:hover {
  background-color: #0b4d6b;
  border: 1px solid #0b4d6b;
  color: #ffffff;
}
.footer-section.startup-footer .shape-1 {
  position: absolute;
  bottom: 90px;
  right: 40px;
  z-index: -1;
  opacity: 0.5;
}
.footer-section.startup-footer .shape-2 {
  position: absolute;
  top: 45px;
  right: 30px;
  z-index: -1;
  opacity: 0.2;
}
.footer-section.startup-footer .shape-3 {
  position: absolute;
  top: 100px;
  left: 20px;
  z-index: -1;
  opacity: 0.5;
}
.footer-section.startup-footer .shape-4 {
  position: absolute;
  bottom: 70px;
  left: 50px;
  z-index: -1;
  opacity: 0.5;
}
.footer-section.seo-footer {
  background-image: unset;
}
.footer-section.seo-footer .footer-area-content p {
  color: #827f8d;
}
.footer-section.seo-footer .single-footer-widget .footer-quick-links.address-link a {
  color: #827f8d;
}
.footer-section.seo-footer .single-footer-widget .footer-quick-links.address-link a i {
  color: #827f8d;
  transition: all 0.5s;
}
.footer-section.seo-footer .single-footer-widget .footer-quick-links.address-link a:hover {
  color: #0b4d6b;
  letter-spacing: normal;
}
.footer-section.seo-footer .single-footer-widget .footer-quick-links.address-link a:hover i {
  color: #ffffff;
}
.footer-section.seo-footer .single-footer-widget .footer-quick-links a {
  color: #827f8d !important;
}
.footer-section.seo-footer .single-footer-widget .footer-quick-links a:hover {
  color: #0b4d6b;
  letter-spacing: 1px;
}
.footer-section.freelancer-footer {
  background-image: unset;
  background-color: #141016;
}
.footer-section.freelancer-footer::before {
  display: none;
}
.footer-section.freelancer-footer .footer-area-content p {
  color: #d8c7e2;
  margin-bottom: 28px;
}
.footer-section.freelancer-footer .footer-area-content .default-btn {
  background-color: #0b4d6b;
  color: #ffffff;
}
.footer-section.freelancer-footer .footer-area-content .default-btn:hover {
  background-color: #ffffff;
  color: #0b4d6b;
  border-color: #ffffff;
}
.footer-section.freelancer-footer .single-footer-widget h3 {
  color: #ffffff;
}
.footer-section.freelancer-footer .single-footer-widget .footer-quick-links.address-link a {
  color: #d8c7e2;
}
.footer-section.freelancer-footer .single-footer-widget .footer-quick-links.address-link a i {
  color: #d8c7e2;
  transition: all 0.5s;
}
.footer-section.freelancer-footer .single-footer-widget .footer-quick-links.address-link a:hover {
  color: #0b4d6b;
  letter-spacing: normal;
}
.footer-section.freelancer-footer .single-footer-widget .footer-quick-links.address-link a:hover i {
  color: #ffffff;
}
.footer-section.freelancer-footer .single-footer-widget .footer-quick-links a {
  color: #d8c7e2 !important;
}
.footer-section.freelancer-footer .single-footer-widget .footer-quick-links a:hover {
  color: #0b4d6b;
  letter-spacing: 1px;
}
.footer-section.freelancer-footer .copyright-area p {
  color: #ffffff;
}
.footer-section.freelancer-footer .copyright-area p a {
  color: #ffffff;
}
.footer-section.freelancer-footer .copyright-area ul li a {
  color: #ffffff;
}

.modal-dialog {
  margin: auto;
  max-width: 600px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.modal-dialog .startup-form {
  max-width: 550px;
  position: relative;
  margin: auto;
}
.modal-dialog .startup-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #f1f1f1;
  border: none;
  height: 64px;
  padding-left: 25px;
  border-radius: 4px;
  padding-top: 0;
  outline: 0;
  color: #343b4e;
}
.modal-dialog .startup-form .input-newsletter::placeholder {
  color: #737b9a;
}
.modal-dialog .startup-form button {
  position: absolute;
  right: 7px;
  top: 7px;
  background-color: #0b4d6b;
  color: #ffffff;
  border: none;
  padding: 14px 30px;
  border-radius: 5px;
  transition: 0.5s;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  font-size: 15px;
  font-weight: 400;
}
.modal-dialog .startup-form button:hover {
  background-color: #343b4e;
  color: #ffffff;
}
.modal-dialog .startup-form button i {
  margin-left: 5px;
}
.modal-dialog .modal-header .close {
  padding: 0;
  margin: 0;
}
.modal-dialog .modal-body {
  padding: 50px 30px;
}

.footer-area-content p {
  color: #737b9a;
  font-weight: 400;
  margin: 25px 0 0 0;
}

.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget h3 {
  margin-bottom: 30px;
  color: #3e435e;
  position: relative;
  font-size: 20px;
}
.single-footer-widget .footer-quick-links {
  padding-left: 0;
  margin-bottom: 0;
}
.single-footer-widget .footer-quick-links li {
  list-style-type: none;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 15px;
}
.single-footer-widget .footer-quick-links li:last-child {
  padding-bottom: 0;
}
.single-footer-widget .footer-quick-links a {
  color: #737b9a;
  transition: 0.5s;
}
.single-footer-widget .footer-quick-links a:hover {
  color: #0b4d6b;
  letter-spacing: 1px;
}
.single-footer-widget .footer-quick-links.address-link li {
  padding-bottom: 0;
}
.single-footer-widget .footer-quick-links.address-link a {
  color: #737b9a;
  position: relative;
  padding-left: 40px;
  display: block;
  margin-bottom: 20px;
}
.single-footer-widget .footer-quick-links.address-link a i {
  position: absolute;
  left: 0;
  top: -3px;
  color: #737b9a;
  width: 30px;
  height: 30px;
  border: 1px solid #737b9a;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  transition: all 0.5s;
}
.single-footer-widget .footer-quick-links.address-link a:hover {
  color: #0b4d6b;
  letter-spacing: normal;
}
.single-footer-widget .footer-quick-links.address-link a:hover i {
  color: #ffffff;
  background-color: #0b4d6b;
  border-color: #0b4d6b;
}
.single-footer-widget .social-list {
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 25px;
}
.single-footer-widget .social-list li {
  display: inline-block;
  margin-right: 5px;
}
.single-footer-widget .social-list li.list-heading {
  color: #737b9a;
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 400;
}
.single-footer-widget .social-list li a {
  width: 35px;
  height: 35px;
  line-height: 32px;
  background: transparent;
  border: 1px solid #737b9a;
  color: #737b9a;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  transition: 0.6s;
  -webkit-transition: 0.5s;
}
.single-footer-widget .social-list li a:hover {
  background-color: #0b4d6b;
  border: 1px solid #0b4d6b;
  color: #ffffff;
}

.copyright-area {
  margin-top: 40px;
  border-top: 1px solid #eeeeee;
  font-size: 14px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.copyright-area p {
  color: #737b9a;
  font-weight: 500;
}
.copyright-area p a {
  color: #737b9a;
  display: inline-block;
  font-weight: 500;
}
.copyright-area p a:hover {
  color: #0b4d6b;
}
.copyright-area ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: right;
}
.copyright-area ul li {
  display: inline-block;
  color: #737b9a;
  font-size: 15px;
  position: relative;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 12px;
}
.copyright-area ul li a {
  display: inline-block;
  color: #737b9a;
}
.copyright-area ul li a:hover {
  color: #0b4d6b;
}
.copyright-area ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -13px;
  width: 1px;
  height: 14px;
  background-color: #a0a0a0;
}
.copyright-area ul li:last-child {
  margin-right: 0;
}
.copyright-area ul li:last-child::before {
  display: none;
}
.copyright-area ul li:first-child {
  margin-left: 0;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: #0b4d6b;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
  border-radius: 50%;
}
.go-top.active {
  top: 98%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 0.5s;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #343b4e;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  border-radius: 50%;
}
.go-top:hover, .go-top:focus {
  color: #ffffff;
}
.go-top:hover::before, .go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child, .go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child, .go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

/*
Sidebar Menu Style
================================*/
.sidebar-modal {
  position: relative;
}
.sidebar-modal .navbar-nav li a {
  padding: 10px 0px 10px 15px;
}
.sidebar-modal .modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}
.sidebar-modal .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
  background-color: #ffffff;
}
.sidebar-modal .modal.right .modal-body {
  padding: 30px;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget {
  margin-bottom: 35px;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .title {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
  color: #343b4e;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .title::before {
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 70px;
  height: 2px;
  border-radius: 5px;
  background: #343b4e;
  content: "";
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget:last-child {
  margin-bottom: 0;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul li {
  margin-bottom: 10px;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul li:last-child {
  margin-bottom: 0;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  text-transform: capitalize;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul li a:hover {
  color: #343b4e;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul.contact-info li {
  position: relative;
  font-weight: 700;
  padding-left: 45px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
  color: #343b4e;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul.contact-info li span {
  font-size: 13px;
  display: block;
  font-weight: 400;
  color: #818992;
  margin-top: 5px;
  text-transform: initial;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul.contact-info li i {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px dashed #0b4d6b;
  border-radius: 50%;
  color: #0b4d6b;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 24px;
  font-size: 29px;
  transition: all 0.5s;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul.contact-info li i::before {
  margin-left: 0;
  font-size: 15px;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul.contact-info li:hover i {
  color: #ffffff;
  border-color: #0b4d6b;
  background: #0b4d6b;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget ul.contact-info li:last-child {
  margin-bottom: 0;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .social-list {
  text-align: left;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .social-list li {
  display: inline-block;
  padding-right: 5px;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .social-list li a i {
  width: 25px;
  height: 25px;
  border: 1px dashed #0b4d6b;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  color: #0b4d6b;
  font-size: 12px;
  transition: all 0.5s;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .social-list li a i:hover {
  color: #ffffff;
  background-color: #0b4d6b;
  border-color: #0b4d6b;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .social-list li:hover {
  color: #ffffff;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .social-list li a:hover {
  color: #ffffff;
}
.sidebar-modal .modal.right .modal-body .sidebar-modal-widget img {
  margin-bottom: 20px;
}
.sidebar-modal .modal.right.fade.show .modal-dialog {
  right: 0;
  transition: all 0.3s ease-in-out;
}
.sidebar-modal .modal.right.fade .modal-dialog {
  right: -320px;
  transition: all 0.3s ease-in-out;
}
.sidebar-modal .modal-header {
  display: inline;
  padding: 0;
  border: none;
}
.sidebar-modal .modal-header .close {
  height: 30px;
  width: 30px;
  color: #343b4e;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  opacity: 1;
}
.sidebar-modal .modal-header .close i::before {
  margin-left: 0;
  font-size: 20px;
}
.sidebar-modal .modal-header h2 {
  background-color: #f3f3f3;
  padding: 30px 25px;
}
.sidebar-modal button:focus {
  outline: 0px;
}

.seo-blog-section .blog-title-two {
  text-align: center;
  margin-bottom: 50px;
}
.seo-blog-section .blog-title-two span {
  font-size: 15px;
  color: #3e435e;
  font-weight: 400;
  margin-top: -5px;
  display: block;
}
.seo-blog-section .blog-title-two h3 {
  font-size: 36px;
  margin: 5px 0 10px 0;
  color: #3f3360;
}
.seo-blog-section .single-blog {
  margin-bottom: 30px;
  transition: all 0.5s;
}
.seo-blog-section .single-blog .blog-image {
  position: relative;
  background-color: #ffe6db;
  text-align: center;
  padding: 20px;
}
.seo-blog-section .single-blog .blog-image .blog-bate {
  position: absolute;
  bottom: -10px;
  left: 20px;
  background-color: #0b4d6b;
  padding: 10px 20px;
  text-align: center;
}
.seo-blog-section .single-blog .blog-image .blog-bate span {
  display: block;
  font-size: 20px;
  color: #fff;
  line-height: 1;
}
.seo-blog-section .single-blog:hover {
  transform: translateY(-5px);
}
.seo-blog-section .single-blog-item .blog-content {
  padding: 20px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}
.seo-blog-section .single-blog-item .blog-content .admin {
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}
.seo-blog-section .single-blog-item .blog-content .admin i {
  margin-right: 5px;
}
.seo-blog-section .single-blog-item .blog-content .blog-link h3 {
  font-size: 20px;
  color: #3e435e;
  margin-bottom: 18px;
  transition: all 0.5s;
}
.seo-blog-section .single-blog-item .blog-content .blog-link:hover h3 {
  color: #0b4d6b;
}
.seo-blog-section .single-blog-item .blog-content .read-more i {
  font-size: 12px;
}

.inner-team {
  background-color: #ffffff;
}
.inner-team::before {
  display: none;
}
.inner-team::after {
  display: none;
}

.inner-update-section::before {
  display: none;
}
.inner-update-section::after {
  display: none;
}